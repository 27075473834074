import { useEffect, useState, useCallback } from "react";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { Offer } from "models";
import { useLandscape } from "hooks/useLandscape";
import { offerType, offerwallType } from "types/offerwallType";

interface ScrollToType {
  type: "bear" | "normal";
  slide?: Element;
  scrollValue?: number;
  offerwallType?: offerwallType;
}

export const useScrollToDataPosition = (
  position: number,
  isGetAddOffers: boolean,
  fetchPaginate: (offset: number, index: number) => Promise<Offer[]>,
  ref?: React.MutableRefObject<HTMLDivElement>,
  index?: number,
  isBanner?: boolean
) => {
  const { location } = useSelector(selectRouter);
  const [offer, setOffer] = useState<Offer[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isLandscape = useLandscape();

  const paginate = async (offset: number, index: number) => {
    const additionalOffers: Offer[] = await fetchPaginate(offset, index);
    return additionalOffers;
  };

  useEffect(() => {
    if (!isGetAddOffers || !position) return undefined;
    (async () => {
      const res = await Promise.all(
        [...Array(Math.ceil(position / 10))].map(async (_, i) => paginate((i + 1) * 10, index))
      );
      const pagenates = res.reduce((item, array) => item.concat(array));
      setOffer(pagenates);
      setIsLoaded(true);
    })();
    return () => {
      setOffer(undefined);
      setIsLoaded(false);
    };
  }, [isGetAddOffers]);

  const scrollTo = useCallback(
    async ({ type, slide, scrollValue, offerwallType }: ScrollToType) => {
      // 現在アクティブのスクロールコンテナを取得する
      // 新マンガタイプではスライドを渡さないとref.current(ページ全体)がactiveContainerになっていて、51行目のquerySelectorで必ず人気タブのオファーの情報が選ばれていたため、slideを渡すようにしました

      if (!slide) return;

      // スワイパーのリストかオススメから遷移するカテゴリのリストか
      const targetOffer = slide.querySelector(`[data-position="${position}"]`);
      const isLandscapeScrollValue =
        offerwallType === offerType.MANGA && isLandscape
          ? 25
          : type === "bear" && isLandscape
          ? 45
          : isLandscape
          ? -5
          : 0;

      const targetPosition = targetOffer?.getBoundingClientRect().top + isLandscapeScrollValue; // 指定要素のy座標を取得
      const offerlistPaddingTripMile = isBanner ? -35 : 0;

      const offerlistPadding =
        offerwallType === offerType.TRIP_MILE && type === "normal"
          ? offerlistPaddingTripMile
          : type === "normal"
          ? 65
          : 0;
      const targetY =
        targetPosition +
        window.pageYOffset -
        scrollValue -
        offerlistPadding +
        isLandscapeScrollValue;
      const addScrollValue = offerwallType === offerType.MANGA ? 110 : 90;

      if (!position) {
        slide ? slide?.scrollTo(0, 0) : window?.scrollTo(0, 0);
      }

      // borderを追加したので、border分取るように
      // 新マンガタイプではactiveContainerにswiper-slide-activeクラスの内容が入っていて.scrollToができないので、ref.currentをscrollさせるようにしている
      if (targetPosition) {
        if (type === "bear") {
          setTimeout(
            () =>
              slide
                ? slide.scrollTo(0, scrollValue ? targetY : targetPosition - addScrollValue)
                : window.scrollTo(0, scrollValue ? targetY : targetPosition - addScrollValue),
            0
          );
        } else {
          setTimeout(() => window.scrollTo(0, targetY), 0);
        }
      }
    },
    [location, ref]
  );
  return {
    getAddOffers: offer,
    scrollTo,
    isLoaded,
  };
};
