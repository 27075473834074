import { datadogLogs } from "@datadog/browser-logs";
/**
 * datadogのログ収集用の関数
 */
export default {
  logs: {
    init: () =>
      datadogLogs.init({
        clientToken: "pub11a9452c4191bb2ec3bad3fac8cfef2b",
        site: "datadoghq.com",
        service: "ow",
        env: process?.env?.REACT_APP_DATADOGLOGS_ENV,
        version: "2.0.0",
        forwardErrorsToLogs: true,
        sampleRate: 100,
      }),
    info: (offerId: string) => datadogLogs.logger.info("get offer detail", { offerId }),
  },
};
