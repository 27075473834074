import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";
import { toBoolean } from "utiles/toType";

// stateの型
export interface Popup {
  isPopup: boolean;
}

export const PopupState: Popup = {
  isPopup: toBoolean(session("isPopup")),
};

const PopupModules = createSlice({
  name: "PouppModules",
  initialState: PopupState,
  reducers: {
    setIsPopup: (state, action: PayloadAction<boolean>) => {
      setSession("isPopup", action.payload);
      return {
        ...state,
        isPopup: action.payload,
      };
    },
  },
});

export default PopupModules;
