import { Box, Typography } from "@material-ui/core";
import { Footer } from "components/shared/Footer";
import { Menus } from "models";
import { offerwallType } from "types/offerwallType";
import { offerType } from "utiles/media";
import { Menu } from "./Menu";
import Styles from "stylesheets/index/NoOfferContent.module.scss";

interface NoOfferContentProps {
  switchMenu?: () => void;
  mediaData: {
    offerwallType?: offerwallType;
    menus: Menus[];
    colors?: {
      titleBgColor?: string;
      titleTxtColor?: string;
    };
    footerText: string;
    privacyPolicyLinkUrl?: string;
    cookiePolicyLinkUrl?: string;
    anotherCustomerSupportEmailAddress?: string;
  };
  isMenu: boolean;
}

const NoOfferContent: React.FC<NoOfferContentProps> = ({ mediaData, switchMenu, isMenu }) => (
  <>
    {mediaData.offerwallType === offerType.MANGA && (
      <Menu
        switchMenu={switchMenu}
        menus={mediaData.menus}
        color={{
          bgColor: mediaData?.colors?.titleBgColor,
          txtColor: mediaData?.colors?.titleTxtColor,
        }}
        isMenuOpen={isMenu}
        offerwallType={mediaData.offerwallType}
      />
    )}
    <Box className={Styles.noOfferContent}>
      <Typography variant="h5" style={{ color: mediaData?.colors?.titleTxtColor }}>
        挑戦可能な案件がありません
      </Typography>
    </Box>
    <Footer
      color={{
        bgColor: mediaData?.colors?.titleBgColor,
        txtColor: mediaData?.colors?.titleTxtColor,
      }}
      menus={mediaData.menus}
      text={mediaData.footerText}
      offerwallType={mediaData.offerwallType}
      privacyPolicyUrl={mediaData.privacyPolicyLinkUrl}
      cookiePolicyUrl={mediaData.cookiePolicyLinkUrl}
      customerSupportEmail={mediaData.anotherCustomerSupportEmailAddress}
    />
  </>
);

export default NoOfferContent;
