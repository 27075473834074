import React from "react";
import styles from "SkyflagFrontendOfferwall/stylesheets/common/Button.module.scss";
import clsx from "clsx";

interface Color {
  bgColor?: string;
  txtColor?: string;
}

export interface Props {
  title: string | React.ReactNode;
  className?: string;
  color?: Color;
  onClick?: () => void;
  tag?: "a" | "button" | "submit";
  type?: "primary" | "secondary";
  isDisabled?: boolean;
  href?: string;
}

export const Button = ({
  title,
  color,
  className,
  onClick,
  tag = "button",
  type = "primary",
  isDisabled,
  href,
}: Props) =>
  type === "primary" && tag === "button" ? (
    <button
      style={{ backgroundColor: color?.bgColor, color: color?.txtColor }}
      className={clsx(styles.primary, styles.button, className)}
      data-testid="btn"
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "button" ? (
    <button
      style={{
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      className={clsx(styles.secondary, styles.button)}
      data-testid="btn"
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "submit" ? (
    <button
      style={{
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      className={clsx(styles.secondary, styles.button, className)}
      data-testid="btn"
      type="submit"
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "a" ? (
    <a
      href={href}
      className={clsx(styles.secondary, styles.button, styles.link, className)}
      target="_blank"
      rel="noreferrer"
      style={{
        pointerEvents: isDisabled ? "none" : "auto",
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      data-testid="btn"
    >
      {title}
    </a>
  ) : (
    <></>
  );
