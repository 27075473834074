import React from "react";
import Menu from "@material-ui/icons/Menu";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import { SvgIcon } from "@mui/material";
import { ReactComponent as PiccomaWebMenu } from "images/piccoma_web_menu.svg";
import clsx from "clsx";
import { offerType } from "utiles/media";
import Styles from "stylesheets/index/MenuIcon.module.scss";
import torimaIcon from "images/torima_skyflag_menu_button.png";
import { offerwallType } from "types/offerwallType";

interface Props {
  className?: string;
  isMenu?: boolean;
  iconType?: "primary" | "secondary";
  switchMenu?: () => void;
  offerwallType?: offerwallType;
}

export const MenuIcon = ({
  className,
  isMenu = false,
  switchMenu,
  iconType = "primary",
  offerwallType,
}: Props) => (
  <>
    {!isMenu && iconType === "secondary" ? (
      <SvgIcon
        component={PiccomaWebMenu}
        className={clsx(CommonStyles.materialIcon, className)}
        onClick={switchMenu}
        data-testid="secondaryMenu"
      />
    ) : offerwallType === offerType.TRIP_MILE ? (
      <img
        src={torimaIcon}
        className={`${clsx(CommonStyles.materialIcon, className)} ${Styles.torima} 
        ${isMenu && Styles.slideUpIcon}`}
        onClick={switchMenu}
        data-testid="primaryMenu"
      />
    ) : !isMenu ? (
      <Menu
        className={clsx(CommonStyles.materialIcon, className)}
        onClick={switchMenu}
        data-testid="primaryMenu"
      />
    ) : (
      <ArrowBackIos
        className={clsx(CommonStyles.materialIcon, className)}
        onClick={switchMenu}
        data-testid="backIconMenu"
      />
    )}
  </>
);
