import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";

// stateの型
export interface DisplayIndex {
  displayIndex: number;
}

export const DisplayIndexState: DisplayIndex = {
  displayIndex: Number(session("displayIndex")),
};

const DisplayIndexModules = createSlice({
  name: "DisplayIndexModules",
  initialState: DisplayIndexState,
  reducers: {
    setDisplayIndex: (state, action: PayloadAction<number>) => {
      setSession("displayIndex", action.payload);
      return {
        ...state,
        displayIndex: action.payload,
      };
    },
  },
});

export default DisplayIndexModules;
