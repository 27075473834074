import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";
import { toBoolean } from "utiles/toType";

// stateの型
export interface Menu {
  isMenu: boolean;
}

export const MenuState: Menu = {
  isMenu: toBoolean(session("isMenu")),
};

const MenuModules = createSlice({
  name: "MenuModules",
  initialState: MenuState,
  reducers: {
    setIsMenu: (state, action: PayloadAction<boolean>) => {
      setSession("isMenu", action.payload);
      return {
        ...state,
        isMenu: action.payload,
      };
    },
  },
});

export default MenuModules;
