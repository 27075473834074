import { useState } from "react";
import { offerApi } from "apis/offer";
import { removeEmpty } from "modules/removeEmpty";

export const useTutorialOfferConversion = (tutorialOfferConversionUrl: string) => {
  const [hasErrorConversion, setHasErrorConversion] = useState(false);
  const [isConversion, setIsConversion] = useState(false);
  const [isConversionFetching, setIsConversionFetching] = useState(false);

  const getTutorialOfferConversionStatus = () => {
    // API呼び出し前に状態をリセットしてフェッチ開始を示す
    setHasErrorConversion(false);
    setIsConversionFetching(true);

    const url = new URL(tutorialOfferConversionUrl);
    offerApi
      .getTutorialOfferConversionStatus(removeEmpty(url.searchParams))
      .then(() => {
        setIsConversion(true);
        setIsConversionFetching(false);
      })
      .catch(() => {
        setHasErrorConversion(true);
        setIsConversion(false);
        setIsConversionFetching(false);
      });
  };

  return {
    hasErrorConversion,
    isConversion,
    isConversionFetching,
    getTutorialOfferConversionStatus,
  };
};
