import { Link } from "react-router-dom";
import { OfferIcon } from "components/shared/OfferIcon";
import Styles from "stylesheets/index/HorizontalOffersList.module.scss";
import { PointResult } from "components/index/PointResult";
import clsx from "clsx";

import { LocationState } from "history";

interface To {
  pathname: string;
  search: string;
  state: LocationState;
}
interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}
interface TimeSale {
  startAt: Date;
  endAt: Date;
}

interface Color {
  textColor: string;
  mcvTextColor: string;
}

export interface Props {
  to: To;
  iconUrl: string;
  superSale: SuperSale;
  timeSale: TimeSale | null;
  basePoint: number | null;
  actualPoint: number;
  pointIconUrl: string;
  isSur: boolean;
  text: string;
  color: Color;
  isPercentage?: boolean;
}

export const OfferBlock = ({
  superSale,
  to,
  iconUrl,
  timeSale,
  basePoint,
  actualPoint,
  pointIconUrl,
  isSur,
  text,
  color,
  isPercentage,
}) => (
  <Link to={to} className={Styles.link}>
    <div className={Styles.icon}>
      <OfferIcon src={iconUrl} isIndex />
      <div className={Styles["coin-wrapper"]}>
        {timeSale && basePoint ? (
          <PointResult
            timeSale={timeSale}
            superSale={superSale}
            basePoint={basePoint}
            actualPoint={actualPoint}
            pointIconUrl={pointIconUrl}
            color={{
              appAreaTxtColor: color.textColor,
            }}
            isSur={isSur}
            isIndex
            coinStyle={Styles.coin}
            isPercentage={isPercentage}
          />
        ) : (
          <>
            {pointIconUrl && <img className={Styles.coin} src={pointIconUrl} alt="コイン" />}
            <span style={{ color: color.textColor }}>
              {actualPoint}
              {isPercentage && "%"}
            </span>
          </>
        )}
      </div>
      {text && (
        <div
          className={clsx(Styles["offer-type-txt"], isSur && Styles.sur)}
          style={{ color: color.mcvTextColor }}
        >
          {text}
        </div>
      )}
    </div>
  </Link>
);
