import { Expose } from "class-transformer";

export class Colors {
  @Expose({ name: "appAreaBgColor" })
  appAreaBgColor: string;

  @Expose({ name: "appAreaTxtColor" })
  appAreaTxtColor: string;

  @Expose({ name: "cvConditionColor" })
  cvConditionColor: string;

  @Expose({ name: "dlBtnBgColor" })
  dlBtnBgColor: string;

  @Expose({ name: "dlBtnTxtColor" })
  dlBtnTxtColor: string;

  @Expose({ name: "expointTxtColor" })
  expointTxtColor: string;

  @Expose({ name: "selectTabColor" })
  selectTabColor: string;

  @Expose({ name: "tabTxtColor" })
  tabTxtColor: string;

  @Expose({ name: "timerBgColor" })
  timerBgColor: string;

  @Expose({ name: "timerTxtColor" })
  timerTxtColor: string;

  @Expose({ name: "timerIconColor" })
  timerIconColor: string;

  @Expose({ name: "timerRatioColor" })
  timerRatioColor: string;

  @Expose({ name: "titleBgColor" })
  titleBgColor: string;

  @Expose({ name: "timerOpacity" })
  timerOpacity: string;

  @Expose({ name: "titleTxtColor" })
  titleTxtColor: string;

  @Expose({ name: "mcvTxtColor" })
  mcvTxtColor: string;

  // 以下、ピッコマテンプレートでは使わない
  @Expose({ name: "expointBgColor" })
  expointBgColor: string;

  @Expose({ name: "tabBgColor" })
  tabBgColor: string;

  @Expose({ name: "mcvBgColor" })
  mcvBgColor: string;

  @Expose({ name: "mcvCheckColor" })
  mcvCheckColor: string;

  @Expose({ name: "mcvCheckTxtColor" })
  mcvCheckTxtColor: string;

  @Expose({ name: "mcvCheckPointColor" })
  mcvCheckPointColor: string;

  @Expose({ name: "mcvCheckPointTxtColor" })
  mcvCheckPointTxtColor: string;
}
