export const MENU_TYPE = {
  // 一覧
  SUMMARY: 1,
  // 挑戦中
  CHALLENGING: 2,
  // 獲得待ち
  ACQUISITION: 3,
  // 獲得履歴
  HISTORY: 4,
  // 事前登録
  REGISTER: 5,
  // お問い合わせ
  CONTACT: 6,
} as const;

export type MenuType = typeof MENU_TYPE[keyof typeof MENU_TYPE];
