import { Expose, Type } from "class-transformer";
import { ScheduledReceivePoints } from "models";

export class Challenging {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline?: string;

  @Expose({ name: "isRestricted" })
  isRestricted?: string;

  @Expose({ name: "receivedPoint" })
  receivedPoint: number;

  @Expose({ name: "receivablePoint" })
  receivablePoint: number;

  @Expose({ name: "cvCondition" })
  cvCondition?: string;

  @Expose({ name: "cvEstimatedTime" })
  cvEstimatedTime?: string;

  @Expose({ name: "displayAppName" })
  displayAppName?: string;

  @Expose({ name: "iconUrl" })
  iconUrl?: string;

  @Expose({ name: "installedAt" })
  installedAt?: string;

  @Expose({ name: "listImageUrl" })
  listImageUrl?: string;

  @Expose({ name: "isEasy" })
  isEasy: boolean;

  @Expose({ name: "isFree" })
  isFree: boolean;

  @Expose({ name: "isPreRegist" })
  isPreRegist: boolean;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Expose({ name: "registeredAt" })
  registeredAt?: string;

  @Expose({ name: "scheduledReceivePoints" })
  @Type(() => ScheduledReceivePoints)
  conversionPoints?: ScheduledReceivePoints[];

  @Expose({ name: "approvedDate" })
  approvedDate?: string;

  @Expose({ name: "challengedDate" })
  challengedDate?: string;

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  get isSur() {
    return this.conversionPoints.length > 0;
  }
}
