import { useRef, useEffect } from "react";
import ReactModal from "react-modal";
import Slider from "react-slick";
import Styles from "stylesheets/detail/TutorialPopup.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import closeIcon from "images/close_icon.png";
import carouselImage1 from "images/tutorial_popup_index_app.png";
import carouselImage1Web from "images/tutorial_popup_index_web.png";
import carouselImage1Line from "images/tutorial_popup_index_line.png";
import carouselImage1Pickup from "images/tutorial1_other.png";
import carouselImage2 from "images/tutorial_popup_detail_app.png";
import carouselImage2Web from "images/tutorial_popup_detail_web.png";
import carouselImage2Line from "images/tutorial_popup_detail_line.png";
import carouselImage2Pickup from "images/tutorial2_other.png";
import carouselImage3 from "images/tutorial_popup_menu_app.png";
import carouselImage3Web from "images/tutorial_popup_menu_web.png";
import carouselImage3Line from "images/tutorial_popup_menu_line.png";
import carouselImage3Pickup from "images/tutorial3_other.png";
import tutorialStanderd1 from "images/tutorial_standard1.png";
import tutorialStanderd2 from "images/tutorial_standard2.png";
import tutorialStanderd3 from "images/tutorial_standard3.png";
import { Carousel } from "components/shared/Carousel";
import { offerType, offerwallType } from "types/offerwallType";
import clsx from "clsx";
import { TutorialBanner } from "models/tutorialBanner";

interface Props {
  offerwallType: offerwallType;
  isOpen: boolean;
  nextpopup: () => void;
  tutorialBanners: TutorialBanner[];
}

export const TutorialPopup = ({ offerwallType, isOpen, nextpopup, tutorialBanners }: Props) => {
  const carouselRef = useRef<Slider>(null);

  useEffect(() => {
    const touchMoveHandler = (event: TouchEvent) => {
      event.preventDefault();
    };
    document.addEventListener("touchmove", touchMoveHandler, { passive: false });
    return () => {
      document.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  const carouselImages = {
    [offerType.LINE_MANGA]: [carouselImage1Line, carouselImage2Line, carouselImage3Line],
    [offerType.PICCOMA_APP]: [carouselImage1, carouselImage2, carouselImage3],
    [offerType.PICCOMA_WEB]: [carouselImage1Web, carouselImage2Web, carouselImage3Web],
    [offerType.PICKUP]: [carouselImage1Pickup, carouselImage2Pickup, carouselImage3Pickup],
    default: [tutorialStanderd1, tutorialStanderd2, tutorialStanderd3],
  };

  const descriptions = [
    <>
      気になるサービスを <br />
      選択します
    </>,
    <>
      詳細を確認して <br />
      サービス登録へ
    </>,
    <>
      トップページのメニューから
      <br />
      各種履歴を確認できます
    </>,
  ];

  const getCarouselImage = (index: number) => {
    if (offerwallType === offerType.PICKUP && tutorialBanners?.[index]?.imageUrl) {
      return tutorialBanners[index].imageUrl;
    }

    let images = carouselImages[offerwallType];
    if (!images) {
      images = carouselImages.default;
    }
    return images[index];
  };

  const getDescription = (index: number) =>
    (offerwallType === offerType.PICKUP && tutorialBanners?.[index]?.description) ||
    descriptions[index];

  const slideIndices =
    offerwallType === offerType.PICKUP && tutorialBanners?.length > 0
      ? [...Array(tutorialBanners.length).keys()]
      : [0, 1, 2];

  const lastSlideIndex = slideIndices.length - 1;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        overlayClassName={Styles.overlay}
        shouldFocusAfterRender={false}
        className={Styles["tutorial-content"]}
        testId="tutorial-popup"
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "auto")}
        ariaHideApp={false}
      >
        <div className={Styles["container-inner"]}>
          <Carousel
            ref={carouselRef}
            settings={{
              arrows: false,
              dots: true,
              infinite: false,
              className: Styles["styled-slider-div"],
              dotsClass: clsx(Styles["styled-slick-dots"], "slick-dots"),
              beforeChange: (currentSlide: number, nextSlide: number) => {
                if (currentSlide === lastSlideIndex && currentSlide === nextSlide) {
                  nextpopup();
                }
              },
            }}
          >
            {slideIndices.map((index) => (
              <div key={index}>
                <div className={Styles.description}>{getDescription(index)}</div>
                <img className={Styles["carousel-image"]} src={getCarouselImage(index)} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={Styles["button-wrapper"]}>
          <img className={Styles["close-icon"]} src={closeIcon} onClick={() => nextpopup()} />
        </div>
      </ReactModal>
    </>
  );
};
