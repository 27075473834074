import { useEffect } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import closeIcon from "images/close_icon.png";
import Styles from "stylesheets/index/RecommendCampaign.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import { Button } from "components/common/Button";
import { Link } from "react-router-dom";
import PopupModules from "slices/popup";
import Countdown from "components/shared/Countdown";

interface Color {
  appAreaBgColor: string;
  appAreaTxtColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}
interface Props {
  linkUrl: string;
  color: Color;
  imageUrl: string;
  name?: string;
  dlBtnName?: string;
  isOpen: boolean;
  isTripMile: boolean;
  startAt?: Date;
  endAt?: Date;
  nextpopup: () => void;
}

export const RecommendCampaign = ({
  linkUrl,
  color,
  imageUrl,
  name,
  dlBtnName,
  isOpen,
  isTripMile,
  startAt,
  endAt,
  nextpopup,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const touchMoveHandler = (event: { preventDefault: () => void }) => {
      event.preventDefault();
    };
    document.addEventListener("touchmove", touchMoveHandler, { passive: false });
    return () => {
      document.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        overlayClassName={Styles.overlay}
        shouldFocusAfterRender={false}
        className={Styles["offer-content"]}
        testId="popup"
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "auto")}
        ariaHideApp={false}
      >
        <div className={Styles.wrapper}>
          <Link
            to={linkUrl}
            className={Styles.link}
            onClick={() => dispatch(PopupModules.actions.setIsPopup(true))}
          >
            {imageUrl && (
              <img
                src={imageUrl}
                alt="バナー"
                style={{ background: color.appAreaBgColor }}
                className={Styles.image}
              />
            )}
            <div
              className={Styles.descriptionWrapper}
              style={{ backgroundColor: color.appAreaBgColor }}
            >
              {name && (
                <div className={Styles.description}>
                  <div className={Styles.title} style={{ color: color.appAreaTxtColor }}>
                    {name.includes("{timer}") ? (
                      <Countdown startAt={startAt} endAt={endAt} format={name} />
                    ) : (
                      name
                    )}
                  </div>
                </div>
              )}
              <Button
                title={dlBtnName}
                className={isTripMile ? Styles.detailButtonTripMile : Styles.detailButton}
                color={{
                  bgColor: color.dlBtnBgColor,
                  txtColor: color.dlBtnTxtColor,
                }}
                isPortraitFixed={isTripMile}
              />
            </div>
          </Link>
        </div>
        <div className={Styles["button-wrapper"]}>
          <img className={Styles["close-icon"]} src={closeIcon} onClick={() => nextpopup()} />
        </div>
      </ReactModal>
    </>
  );
};
