import { RootState } from "store";
import { Modal } from "slices/modal";
import { Menu } from "slices/menu";
import { Popup } from "slices/popup";
import { History } from "slices/history";
import { Media } from "slices/media";
import { RouterState } from "connected-react-router";
import { LocationState } from "types/locationType";
import { OfferwallTop } from "slices/offerwall";
import { DisplayIndex } from "slices/displayIndex";
import { ServiceType } from "slices/serviceType";
import { TutorialBanners } from "slices/tutorialBanners";
import { TutorialHeaderImage } from "slices/tutorialHeaderImage";

export const selectModalState = (store: RootState): Modal => store.modal;
export const selectMenuState = (store: RootState): Menu => store.menu;
export const selectPopupState = (store: RootState): Popup => store.popup;
export const selectHistoryState = (store: RootState): History => store.history;
export const selectMediaState = (store: RootState): Media => store.media;
export const selectOfferwallState = (store: RootState): OfferwallTop => store.offerwall;
export const selectDisplayIndexState = (store: RootState): DisplayIndex => store.displayIndex;
export const selectServiceTypeState = (store: RootState): ServiceType => store.serviceType;
export const selectRouter = (store: RootState): RouterState<LocationState> => store.router;
export const selectTutorialBannersState = (store: RootState): TutorialBanners =>
  store.tutorialBanners;
export const selectTutorialHeaderImageState = (store: RootState): TutorialHeaderImage =>
  store.tutorialHeaderImage;
