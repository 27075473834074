// 古いブラウザや非対応のブラウザなどに対して互換性のないライブラリを機能させるようにするためのファイル
// ライブラリ関数のpolyfillコードを調べ下記に追加すると機能するようになる

if (!Object.fromEntries) {
  Object.fromEntries = function (iterable) {
    return [...iterable].reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {});
  };
}
