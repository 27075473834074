import Styles from "stylesheets/error/NotFound.module.scss";

interface NotFoundsProps {
  resetErrorBoundary: () => void;
}

// const OtherErrors: React.FC<NotFoundsProps> = ({ resetErrorBoundary }) => (
const OtherErrors: React.FC<NotFoundsProps> = () => (
  // const queries = new URLSearchParams(window.location.search);
  // const owp = queries.get("_owp") ?? "";
  // const isPikkoma =
  //  owp === process.env.REACT_APP_PICCOMA_APP_IOS_OWP ||
  //  owp === process.env.REACT_APP_PICCOMA_WEB_IOS_OWP ||
  //  owp === process.env.REACT_APP_PICCOMA_APP_ANDLOID_OWP ||
  //  owp === process.env.REACT_APP_PICCOMA_WEB_ANDLOID_OWP;

  // const closeWindow = () => {
  //  resetErrorBoundary();
  //  window.close();
  // };

  <div className={Styles.wrapper}>
    <div className={Styles.container}>
      <h1>エラー</h1>
      <p>
        申し訳ございませんが、 ご利用の端末のOSバージョンは現在サポートされておりません。
        <br />
        最新のOSバージョンにアップデートするか、別のサポート対象の端末をご利用いただくようお願いいたします。
        <br />
        <br />
        問題が解決しない場合は、しばらく時間をおいてから再度お試しください。
      </p>
      <br />
      {/* {isPikkoma && <button onClick={closeWindow}>ページを閉じる</button>} */}
    </div>
  </div>
);
export default OtherErrors;
