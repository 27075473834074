import { Tabs } from "../slices/offerwall";
// タブの種類を設定 （メディアによって異なる）
interface ChangeData {
  id: number;
  name: string;
}
// idに一致するデータを取得
const tabsRenameToFindData = (id: number, changeData: ChangeData[]) =>
  changeData.find((data) => data.id === id);
// idと一致しているかを判別する
const isTabsId = (id: number, changeData: ChangeData[]) =>
  changeData.some((data) => data.id === id);
/**
 * idが一致するタブの名前を変更する関数
 * @param tabs タブデータ
 * @param changeData 変換するタブ名データ
 * @return 変換されたタブの配列
 */
export const tabsRename = (tabs: Tabs[], changeData: ChangeData[]) =>
  tabs.map((data) =>
    isTabsId(data.tab, changeData)
      ? { ...data, name: tabsRenameToFindData(data.tab, changeData).name }
      : { ...data }
  );
