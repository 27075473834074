import { Expose } from "class-transformer";

export class SuperSales {
  @Expose({ name: "pointMultiplier" })
  pointMultiplier: number;

  @Expose({ name: "startAt" })
  startAt: Date;

  @Expose({ name: "endAt" })
  endAt: Date;
}
