import { FC, ReactNode, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/TutorialOfferWrapper.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import fingerImage from "images/finger.png";
import bonusGetImage from "images/bonus_get.png";
import bonusGetAchieveImage from "images/bonus_get_achieve.png";
import presentsImage from "images/presents.png";
import { useTutorialOffer } from "hooks/useTutorialOffer";
import { useTutorialOfferConversion } from "hooks/useTutorialOfferConversion";

interface PointProps {
  pointUnit: string;
  basePoint?: number;
  actualPoint?: number;
  isSale?: boolean;
}
interface TutorialOfferWrapperProps {
  children: ReactNode;
  isTutorial: boolean;
  tutorialOfferActionUrl: string;
  pointProps: PointProps;
  viewType: "top" | "detail";
  isBigImage?: boolean;
}

interface BonusGetPageContentProps {
  isClickFetching: boolean;
  isConversionFetching: boolean;
  isTutorialOfferClicked: boolean;
  isConversion: boolean;
  hasErrorConversion: boolean;
  handleGetBonusButton: () => void;
}

const modalFadeColor = { backgroundColor: "rgba(49, 49, 49, 0.9)" };

const BonusGetPageContent: React.FC<BonusGetPageContentProps> = ({
  isClickFetching,
  isConversionFetching,
  isTutorialOfferClicked,
  isConversion,
  hasErrorConversion,
  handleGetBonusButton,
}) => {
  const isFetching = isClickFetching || isConversionFetching;
  return (
    <>
      {isFetching ? (
        <div className={Styles.fitchCard}>
          <div className={FontStyles.bold}>通信中・・・</div>
        </div>
      ) : (
        <>
          <img className={Styles.getBonusImage} src={bonusGetImage} alt="ボーナスGET" />
          <img className={Styles.presentsImage} src={presentsImage} alt="プレゼントボックス" />
          <div className={Styles.getBonusCard}>
            <div className={FontStyles.bold}>
              <p>
                <span className={FontStyles.red}>おめでとうございます！</span>この他にも条件を
              </p>
              <p>達成することでたくさんのボーナスがもらえます！</p>
              <p>ぜひチャレンジしてくださいね！</p>
            </div>
          </div>
        </>
      )}

      {!isFetching && isTutorialOfferClicked && !isConversion && (
        <button className={Styles.getBonusButton} onClick={handleGetBonusButton}>
          ボーナスをうけとる
        </button>
      )}

      {hasErrorConversion && (
        <div className={Styles.errorText}>通信エラー：ボーナスをうけとることができませんでした</div>
      )}
    </>
  );
};

const removePathSegmentAndQueryParams = (
  url: string,
  pathSegmentToRemove: string,
  paramsToRemove: string[]
): string => {
  const urlObj = new URL(url);

  // パスセグメントを分割してフィルタリング
  const pathSegments = urlObj.pathname
    .split("/")
    .filter((segment) => segment && segment !== pathSegmentToRemove);
  urlObj.pathname = pathSegments.join("/");

  // 指定されたクエリパラメータを削除
  paramsToRemove.forEach((param) => urlObj.searchParams.delete(param));

  // 新しいURLを返す
  return urlObj.toString();
};

const TutorialOfferWrapper: FC<TutorialOfferWrapperProps> = ({
  children,
  isTutorial,
  tutorialOfferActionUrl,
  pointProps,
  viewType,
  isBigImage,
}) => {
  // チュートリアル広告以外は早期リターン
  if (!isTutorial) {
    return <>{children}</>;
  }

  const [isSkip, setIsSkip] = useState(false);
  const [isBonusGetPage, setIsBonusGetPage] = useState(false);

  // ブラウザバックではAPIを叩き直せないのでtopに遷移することでAPIを再度叩く
  const currentUrl = window.location.href;
  const pathSegmentToRemove = "detail";
  const paramsToRemove = ["offer", "position", "linkfrom"];
  const topUrl = removePathSegmentAndQueryParams(currentUrl, pathSegmentToRemove, paramsToRemove);

  const {
    hasError,
    isTutorialOfferClicked,
    conversionUrl,
    isClickFetching,
    getTutorialOfferEventConversionUrl,
  } = useTutorialOffer(tutorialOfferActionUrl);

  const {
    hasErrorConversion,
    isConversion,
    isConversionFetching,
    getTutorialOfferConversionStatus,
  } = useTutorialOfferConversion(conversionUrl);

  useEffect(() => {
    if (hasError) {
      setIsBonusGetPage(false);
    }
  }, [hasError]);

  useEffect(() => {
    if (isConversion) {
      location.assign(topUrl); // topUrlに遷移し直すことでtopのAPIを再度叩く
    }
  }, [isConversion]);

  const preventEvent = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  };

  const handleSkipTutorial = () => {
    if (viewType === "top") {
      window.location.reload();
    } else {
      location.assign(topUrl); // topUrlに遷移し直すことでtopのAPIを再度叩く
    }
  };

  const handleSkipButton = () => {
    setIsSkip(true);
  };

  const handleSkipCancel = () => {
    setIsSkip(false);
  };

  const handleClickEventButton = () => {
    getTutorialOfferEventConversionUrl();
    setIsBonusGetPage(true);
  };

  const handleGetBonusButton = () => {
    getTutorialOfferConversionStatus();
  };

  if (isSkip) {
    return (
      <Modal
        open
        BackdropProps={{
          onClick: preventEvent,
          onScroll: preventEvent,
          style: modalFadeColor,
        }}
        onTouchMove={preventEvent}
      >
        <div className={Styles.skipContainer}>
          <div className={Styles.skipCard}>
            <div className={Styles.skipText}>
              <Typography variant="h6">本当にスキップしますか？</Typography>
              <div className={clsx(FontStyles.bold, FontStyles.red, Styles.skipText)}>
                <p>スキップすると</p>
                <p>チュートリアル報酬はもらえません！</p>
                <p>※チュートリアルは再度出現しません</p>
              </div>
              <button className={Styles.backButton} onClick={handleSkipCancel}>
                戻る
              </button>
              <button className={Styles.skipConfirmButton} onClick={handleSkipTutorial}>
                スキップする
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      open
      BackdropProps={{
        onClick: preventEvent,
        onScroll: preventEvent,
        style: modalFadeColor,
      }}
      onTouchMove={preventEvent}
      className={Styles.modal}
    >
      <>
        {!isBonusGetPage && (
          <button className={Styles.skipButton} onClick={handleSkipButton}>
            skip
          </button>
        )}
        {viewType === "top" && (
          <>
            <div className={isBigImage && Styles.topContainer}>
              <img
                className={Styles.tutorialImg}
                src={bonusGetAchieveImage}
                alt="条件達成でボーナスGET"
              />
              <div className={Styles.topSpeechBubble}>
                <div className={FontStyles.bold}>
                  <p>
                    ようこそ！ここは、<span className={FontStyles.red}>条件を達成すること</span>で
                  </p>
                  <p>{pointProps.pointUnit}がもらえるコーナーです。</p>
                  <p>
                    今回は
                    <span className={FontStyles.red}>
                      チュートリアルを進めるだけで無料で報酬が貰えます！
                    </span>
                  </p>
                  <p>早速チャレンジしてみましょう！</p>
                </div>
              </div>
              <div className={Styles.tutorialOffer}>{children}</div>
            </div>
            <img
              className={isBigImage ? Styles.bigImageTopFinger : Styles.topFinger}
              src={fingerImage}
              alt="finger"
            />
          </>
        )}
        {viewType === "detail" &&
          (isBonusGetPage ? (
            <BonusGetPageContent
              isClickFetching={isClickFetching}
              isConversionFetching={isConversionFetching}
              isTutorialOfferClicked={isTutorialOfferClicked}
              isConversion={isConversion}
              hasErrorConversion={hasErrorConversion}
              handleGetBonusButton={handleGetBonusButton}
            />
          ) : (
            <>
              <div className={Styles.floatRight}>
                <div className={Styles.detailSpeechBubble}>
                  <div className={FontStyles.bold}>
                    <p>
                      今回は<span className={FontStyles.red}>このボタンをタップ</span>するだけで、
                    </p>
                    <p>
                      <span className={FontStyles.red}>
                        <>
                          {pointProps.isSale ? pointProps.actualPoint : pointProps.basePoint}
                          {pointProps.pointUnit}
                          がもらえます！
                        </>
                      </span>
                      早速タップしましょう！！
                    </p>
                  </div>
                </div>
                <div className={Styles.clickEventButton} onClick={handleClickEventButton}>
                  {children}
                </div>
                <img className={Styles.detailFinger} src={fingerImage} alt="finger" />
              </div>

              {hasError && <div className={Styles.errorText}>通信エラー：獲得できませんでした</div>}
            </>
          ))}
      </>
    </Modal>
  );
};

export default TutorialOfferWrapper;
