import { useState, useLayoutEffect } from "react";
import queryModule from "modules/query";
import query from "utiles/query";
import { owpApi } from "apis/siteIdToOwp";
import defaultPath from "utiles/path";

export const useRedicectToIndexOnSite = () => {
  const [error, setError] = useState(false);
  const obj = queryModule.object(location.search);
  const isSite = queryModule.isQuery({
    search: obj,
    list: query.index.redirect,
  });

  const queries = new URLSearchParams(window.location.search);

  if (!isSite) return [false];

  useLayoutEffect(() => {
    owpApi
      .getOwp(obj._site)
      .then((res: string) => {
        window.location.href = `${defaultPath.OW}/index?${new URLSearchParams({
          ...Object.fromEntries(queries.entries()),
          _owp: res,
        }).toString()}`;
      })
      .catch(() => {
        setError(true);
      });
  }, []);
  return [error];
};
