export default {
  remove: {
    obj: {
      offer: ["0"],
    },
    list: ["offer"],
  },
  conversion: {
    _article: "offer",
  },
  index: {
    redirect: ["_site"],
    remove: {
      obj: {
        position: ["0"],
      },
      list: ["title"],
    },
  },
};
