import React, { memo, useEffect, useState } from "react";
import Slider from "react-slick";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { Banners as BannerType } from "models/banners";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/Banner.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "SkyflagFrontendOfferwall/stylesheets/slick/custom.scss";
import queryConversion from "modules/query";
import defaultPath from "utiles/path";
import { Link } from "react-router-dom";

interface Props {
  data: BannerType[];
}

const Banner = ({ data }: Props) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const { location } = useSelector(selectRouter);
  const queries = new URLSearchParams(window.location.search);
  const bannerStyle: React.CSSProperties = {
    width: "100%",
  };

  const handleTouchStart = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const onImageLoad = (remainingImages) => {
      remainingImages--;
      if (remainingImages === 0) {
        setImagesLoaded(true);
      }
      return remainingImages;
    };

    data?.reduce((remainingImages, banner) => {
      const img = new Image();
      img.src = banner.imageUrl;
      img.onload = () => onImageLoad(remainingImages);
      return remainingImages - 1;
    }, data.length);
  }, [data]);

  const settings = {
    arrows: false, // 矢印非表示
    autoplay: true, // 自動にするか
    className: Styles.slider,
    dots: true, // 点出すか
    dotsClass: Styles.dots,
    infinite: true, // 無限にやるか
    speed: 500, // スライドが流れるスピード
  };

  const orderBanners = () => [...data].sort((a, b) => a.order - b.order);

  // banner.orderをもとにpositionパラメータを付与する
  const queryObj = { ...Object.fromEntries(queries.entries()), position: String(0) };
  const queryString = queryConversion.str(
    queryConversion.removeFromList({ search: queryObj, removeList: ["_owp", "suid", "position"] })
  );

  const queryStringfree = new URLSearchParams(window.location.search);
  queryStringfree.delete("position");
  queryStringfree.delete("tab");
  queryStringfree.set("linkfrom", "cb");

  const setQueries = (banner: BannerType) => {
    const queries = new URLSearchParams(location.search);
    const suid = queries.get("suid") ?? "";
    const isDetailOrCampaign = banner.linkUrl.match("/detail") || banner.linkUrl.match("/campaign");

    // 遷移先に{suid}の文字列があったらクエリパラメータからsuidを取得して置換する
    const resultUrl: string =
      banner.linkUrl.match("{suid}") && suid
        ? banner.linkUrl.replace("{suid}", encodeURIComponent(suid))
        : banner.linkUrl.replace("&suid={suid}", "");

    // バナーの遷移先がOW詳細ページとCPページでない場合はURLを加工しない
    return !isDetailOrCampaign ? resultUrl : `${resultUrl}&${queryString}`;
  };

  const BannerImage = ({ banner, bannerStyle, location }) => {
    if (banner.linkUrl?.includes(`${defaultPath.INDEX}/free`)) {
      return (
        <Link
          className={Styles.menuLink}
          to={{
            pathname: `${defaultPath.INDEX}/free`,
            search: queryStringfree.toString(),
            state: { display: "", previousPath: "", referrer: location },
          }}
        >
          <img src={banner.imageUrl} alt="バナー" style={bannerStyle} />
        </Link>
      );
    }

    if (banner.linkUrl?.includes(`${defaultPath.INDEX}/survey`)) {
      return (
        <Link
          className={Styles.menuLink}
          to={{
            pathname: `${defaultPath.INDEX}/survey`,
            search: queryStringfree.toString(),
            state: { display: "", previousPath: "", referrer: location },
          }}
        >
          <img src={banner.imageUrl} alt="バナー" style={bannerStyle} />
        </Link>
      );
    }

    if (banner.linkUrl) {
      return (
        <a href={setQueries(banner)}>
          <img src={banner.imageUrl} alt="バナー" style={bannerStyle} />
        </a>
      );
    }

    return <img src={banner.imageUrl} alt="バナー" style={bannerStyle} />;
  };

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      {imagesLoaded && (
        <Slider {...settings}>
          {orderBanners().map((banner, i) => (
            <div className={Styles.slide} key={i}>
              <BannerImage banner={banner} bannerStyle={bannerStyle} location={location} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default memo(Banner);
