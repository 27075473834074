import React from "react";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

interface Props {
  className?: string;
}

export const NextIcon: React.FC<Props> = (props) => {
  const { className } = props;
  return <ArrowForwardIos className={className} />;
};
