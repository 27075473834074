import React, { useEffect } from "react";
import { Close, ArrowBackIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { MenuIcon } from "../index/MenuIcon";
import { BackIcon } from "./BackIcon";
import { LocationDescriptorObject } from "history";
import Styles from "stylesheets/shared/Header.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { transitionType as TransitionType } from "types/transitionType";

interface Color {
  bgColor: string;
  txtColor: string;
}

interface Props {
  title: string;
  icon?: string;
  closeIconHide: boolean;
  color: Color;
  closeLink: string;
  isUnderbar?: boolean;
  backIconHide?: boolean;
  isMenu?: boolean;
  iconType?: "primary" | "secondary";
  switchMenu?: () => void;
  onClose?: () => void;
  isFixed?: boolean;
  isWindowClose: boolean;
  isTitleHidden: boolean;
  push?: LocationDescriptorObject;
  topPush?: LocationDescriptorObject;
  isDetail?: boolean;
  isDirect?: boolean;
  transitionType?: number;
}

const useStyles = makeStyles({
  iconStyle: {
    color: (data: Color) => data.txtColor,
    cursor: "pointer",
  },
});

// 初期値を設定したかったのでpropsを外しました
export const Header = ({
  title,
  icon,
  closeIconHide,
  color,
  closeLink,
  backIconHide = false,
  isMenu = false,
  isUnderbar = true,
  switchMenu,
  onClose,
  iconType = "primary",
  isWindowClose = false,
  isTitleHidden = false,
  isFixed = false,
  push,
  topPush,
  isDetail = false,
  isDirect = false,
  transitionType,
}: Props): JSX.Element => {
  const styles = useStyles(color);

  const setTitleRef = () => {
    if (!isTitleHidden && !icon && title) {
      const tag = document.getElementsByTagName("header")[0];
      const headerTitle = tag.querySelectorAll("span")[0];
      const titleRect = tag.getBoundingClientRect();
      // 左右のアイコン+paddingの合計80
      const padding = 80;
      // 標準フォントサイズ
      const standardfontsize = 18;
      const titleWidth = titleRect.width - padding;
      // 18px以上は18pxに固定
      const number =
        titleWidth / title.length > standardfontsize ? standardfontsize : titleWidth / title.length;
      headerTitle.style.fontSize = `${number}px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setTitleRef);
    return () => {
      window.removeEventListener("resize", setTitleRef);
    };
  }, []);

  // MenuIconとBackIconはヘッダーコンポーネント内で完結するようにしたので各コンポーネントで呼ぶ必要を無くしました
  return (
    <>
      <header
        ref={setTitleRef}
        style={{
          backgroundColor: color.bgColor,
          color: color.txtColor,
        }}
        className={clsx(
          Styles.wrapper,
          isUnderbar ? Styles.underbar : undefined,
          isFixed && Styles["is-fixed"]
        )}
      >
        {switchMenu ? (
          <MenuIcon
            className={styles.iconStyle}
            isMenu={isMenu}
            switchMenu={switchMenu}
            iconType={iconType}
          />
        ) : transitionType !== TransitionType.RECOMMEND ? (
          <BackIcon className={styles.iconStyle} hide={backIconHide} push={push} />
        ) : (
          <a href={closeLink}>
            <ArrowBackIos
              className={clsx(CommonStyles.materialIcon, styles.iconStyle)}
              viewBox="-6 0 24 24"
              data-testid="backIcon"
            />
          </a>
        )}
        {transitionType !== TransitionType.RECOMMEND && isDetail && !isDirect ? (
          <Link className={Styles.title__underline} to={topPush}>
            <div className={Styles.title}>
              {icon && <img src={icon} alt="サイトロゴ" className={Styles.title__logo} />}
              {!isTitleHidden && !icon && title && (
                <h1 className={Styles.title__text}>
                  <span style={{ color: color.txtColor }}>{title}</span>
                </h1>
              )}
            </div>
          </Link>
        ) : (
          <div className={Styles.title}>
            {icon && <img src={icon} alt="サイトロゴ" className={Styles.title__logo} />}
            {!isTitleHidden && !icon && title && (
              <h1 className={Styles.title__text}>
                <span style={{ color: color.txtColor }}>{title}</span>
              </h1>
            )}
          </div>
        )}

        {!closeIconHide && isWindowClose ? (
          <div className={Styles.close}>
            <div onClick={onClose} className={Styles.closeLink} data-testid="closeLink">
              <Close className={clsx(styles.iconStyle)} />
            </div>
          </div>
        ) : !closeIconHide ? (
          <div className={Styles.close}>
            <a href={closeLink} className={Styles.closeLink} data-testid="closeLink">
              <Close className={clsx(styles.iconStyle)} />
            </a>
          </div>
        ) : (
          <></>
        )}
      </header>
    </>
  );
};
