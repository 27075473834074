import { useState, useEffect } from "react";

// 縦画面か横画面かを判断する
export const useLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");

    const handleOrientationChange = (event) => {
      setIsLandscape(event.matches);
    };

    // 初回レンダリング時にも一度呼び出す
    handleOrientationChange(mediaQuery);

    // イベントリスナーが存在するか確認してから設定
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener("change", handleOrientationChange);
    } else {
      // 古いブラウザへの対処
      mediaQuery.addListener(handleOrientationChange);
    }

    // コンポーネントがアンマウントされるときにイベントリスナーをクリーンアップ
    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener("change", handleOrientationChange);
      } else {
        // 古いブラウザへの対処
        mediaQuery.removeListener(handleOrientationChange);
      }
    };
  }, []);

  return isLandscape;
};
export default useLandscape;
