import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";

// stateの型
export interface TutorialHeaderImage {
  tutorialHeaderImage: string;
}

export const TutorialHeaderImageState: TutorialHeaderImage = {
  tutorialHeaderImage: String(session("tutorialHeaderImage")),
};

const TutorialHeaderImageModules = createSlice({
  name: "TutorialHeaderImageModules",
  initialState: TutorialHeaderImageState,
  reducers: {
    setTutorialHeaderImage: (state, action: PayloadAction<string>) => {
      setSession("tutorialHeaderImage", action.payload);
      return {
        ...state,
        tutorialHeaderImage: action.payload,
      };
    },
  },
});

export default TutorialHeaderImageModules;
