import { Expose } from "class-transformer";

export class topChallenging {
  id: number;

  @Expose({ name: "iconUrl" })
  iconUrl?: string;

  @Expose({ name: "isPreRegist" })
  isPreRegist: boolean;

  @Expose({ name: "actualPoint" })
  actualPoint?: number;

  @Expose({ name: "isSur" })
  isSur: boolean;
}
