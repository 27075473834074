import React from "react";
import { Link } from "react-router-dom";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { Offer } from "models";
import { OffersListItem, Props as OffersListItemProps } from "./OffersListItem";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/OffersList.module.scss";
import defaultPath from "utiles/path";
import { linkFromType } from "types/linkFromType";
import { SurveyBanner } from "components/common/SurveyBanner";
import { surveyDisplayType } from "types/surveyDisplayType";
import TutorialOfferWrapper from "./TutorialOfferWrapper";
import { offerType, offerwallType } from "types/offerwallType";

export interface Props
  extends Omit<
    OffersListItemProps,
    | "iconUrl"
    | "isPreRegist"
    | "isRecommended"
    | "displayName"
    | "cvCondition"
    | "basePoint"
    | "isSur"
    | "timeSale"
    | "position"
    | "conversionPoints"
    | "actualPoint"
    | "isPercentage"
    | "isTripMile"
  > {
  offers: Offer[];
  linkFrom?: linkFromType;
  bannerImage: string;
  tab: number;
  surveyType: number;
  offerwallType: offerwallType;
}

export const OffersList = React.memo(
  ({
    offers,
    linkFrom,
    bannerImage,
    tab,
    surveyType,
    offerwallType,
    ...offersListItemProps
  }: Props) => {
    const { location } = useSelector(selectRouter);
    const queries = new URLSearchParams(window.location.search);

    const isStandardOrTripMile =
      offerwallType === offerType.MANGA || offerwallType === offerType.TRIP_MILE;

    return (
      <div className={Styles.offerList} id="offerList">
        {offers
          ?.filter((data) => data.id !== 0)
          .map((offerData: Offer, i: number) =>
            i === 1 && tab === 1 && surveyType === surveyDisplayType.BANNER ? (
              <SurveyBanner
                color={{
                  appAreaBgColor: offersListItemProps.color.appAreaBgColor,
                }}
                BannerImage={bannerImage}
                search={{
                  position: 2,
                  linkFrom: "lb",
                }}
                key={i}
              />
            ) : (
              <TutorialOfferWrapper
                isTutorial={offerData.isTutorialOffer && isStandardOrTripMile}
                tutorialOfferActionUrl={offerData.tutorialOfferActionUrl}
                pointProps={{
                  pointUnit: offersListItemProps.pointUnit,
                }}
                viewType="top"
                key={i}
              >
                <Link
                  to={{
                    pathname: `${defaultPath.INDEX}/detail`,
                    search: new URLSearchParams({
                      ...Object.fromEntries(queries.entries()),
                      offer: String(offerData.id),
                      position: String(i + 1),
                      linkfrom: String(linkFrom),
                    }).toString(),
                    state: { referrer: location },
                  }}
                >
                  <OffersListItem
                    position={i + 1}
                    iconUrl={offerData.iconUrl}
                    isRecommended={offerData.isRecommended}
                    displayName={offerData.displayName}
                    cvCondition={offerData.cvCondition}
                    basePoint={offerData.basePoint}
                    actualPoint={offerData.actualPoint}
                    isSur={offerData.isSur}
                    isVideo={offerData.isVideo}
                    isPercentage={offerData.isPercentage}
                    isTripMile={offerwallType === offerType.TRIP_MILE}
                    timeSale={{
                      startAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.startAt,
                      endAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.endAt,
                      point: offerData.conversionPoints.slice(-1)[0]?.timeSale?.point,
                    }}
                    {...offersListItemProps}
                  />
                </Link>
              </TutorialOfferWrapper>
            )
          )}
      </div>
    );
  }
);
