import React from "react";
import { SurveyPointResult } from "SkyflagFrontendOfferwall/components/index/SurveyPointResult";
import { Button } from "SkyflagFrontendOfferwall/components/common/Button";
import stylesResponsive from "SkyflagFrontendOfferwall/stylesheets/index/SurveyListItem.module.scss";
import stylesPortrait from "SkyflagFrontendOfferwall/stylesheets/index/SurveyListItemPortrait.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";
import { OfferIcon } from "components/shared/OfferIcon";
import { offerType } from "utiles/media";
import { offerwallType } from "types/offerwallType";

interface Color {
  appAreaBgColor: string;
  expointBgColor: string;
  expointTxtColor: string;
  mcvBgColor: string;
  mcvTxtColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}
interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

export interface Props {
  displayName: string;
  cvCondition: string;
  color: Color;
  basePoint: number;
  actualPoint: number;
  basePointMin: number;
  actualPointMin: number;
  pointIconUrl: string;
  pointUnit: string;
  superSale: SuperSale;
  situation: string;
  researchId?: string;
  iconUrl: string;
  company: string;
  offerwallType: offerwallType;
}

export const SurveyListItem = React.memo(
  ({
    displayName,
    cvCondition,
    color,
    basePoint,
    actualPoint,
    basePointMin,
    actualPointMin,
    pointIconUrl,
    pointUnit,
    superSale,
    situation,
    researchId,
    iconUrl,
    company,
    offerwallType,
  }: Props) => {
    const Styles = offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;

    return (
      <div className={Styles.wrapper} style={{ backgroundColor: color.appAreaBgColor }}>
        <div className={Styles.offer}>
          <OfferIcon src={iconUrl} />
          <div className={Styles.offer_content}>
            <div className={Styles.offer_content_upper}>
              <div className={Styles.info}>
                <h3 className={Styles.info_name} style={{ color: color.appAreaTxtColor }}>
                  {displayName}
                </h3>
                <div className={Styles.info_cv}>
                  <div
                    className={clsx(CommonStyles.cvCondition, FontStyles.small)}
                    style={{ color: color.cvConditionColor }}
                  >
                    No.{researchId}
                  </div>
                  <div className={clsx(FontStyles.small)} style={{ color: color.cvConditionColor }}>
                    {cvCondition}
                  </div>
                </div>
              </div>
            </div>
            {situation === "未回答" && (
              <Button
                className={Styles.offer_content_button}
                title={
                  <SurveyPointResult
                    superSale={superSale}
                    pointIconUrl={pointIconUrl}
                    pointUnit={pointUnit}
                    basePointMin={basePointMin}
                    actualPointMin={actualPointMin}
                    basePoint={basePoint}
                    actualPoint={actualPoint}
                    isSur={basePoint > basePointMin}
                  />
                }
                color={{
                  bgColor: color.dlBtnBgColor,
                  txtColor: color.dlBtnTxtColor,
                }}
                tag="button"
                type="primary"
              />
            )}
          </div>
        </div>
        <div
          className={clsx(FontStyles.small, Styles["offer_content_transition-destination-text"])}
          style={{ color: color.cvConditionColor }}
        >
          {situation === "未回答"
            ? `※${company}が管理するアンケート回答画面に遷移します`
            : situation === "回答済"
            ? "回答済み"
            : "期限切れ"}
        </div>
      </div>
    );
  }
);
