import { Expose, Type } from "class-transformer";
import { popupOffer } from "models";

export class popup {
  @Expose({ name: "type" })
  type: number;

  @Expose({ name: "offers" })
  @Type(() => popupOffer)
  offers?: popupOffer[];
}
