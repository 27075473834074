import { Expose } from "class-transformer";

export class UserOffer {
  @Expose({ name: "approvedDate" })
  approvedDate?: string;

  @Expose({ name: "challengedDate" })
  challengedDate?: string;

  @Expose({ name: "isApproved" })
  isApproved?: boolean;

  @Expose({ name: "receivedPoint" })
  receivedPoint: number;

  @Expose({ name: "lastCompletedStep" })
  lastCompletedStep: number;
}
