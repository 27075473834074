export default {
  PICCOMA_ID: 1,
};

export const offerType = {
  MANGA: 6,
  PICCOMA_APP: 10,
  PICCOMA_WEB: 11,
  LINE_MANGA: 12,
  PICKUP: 13,
  TRIP_MILE: 14,
};
