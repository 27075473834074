import { Expose } from "class-transformer";

export class PreRegistApi {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline: string;

  @Expose({ name: "cvCondition" })
  cvCondition: string;

  @Expose({ name: "cvEstimatedTime" })
  cvEstimatedTime: string;

  @Expose({ name: "displayAppName" })
  displayAppName: string;

  @Expose({ name: "iconUrl" })
  iconUrl: string;

  @Expose({ name: "listImageUrl" })
  listImageUrl: string;

  @Expose({ name: "registeredAt" })
  registeredAt: string;

  @Expose({ name: "isPublishStarted" })
  isPublishStarted: boolean;

  @Expose({ name: "registrationDate" })
  registrationDate: string;

  @Expose({ name: "downloadUrl" })
  downloadUrl: string;
}
