export const DESCRIPTION_TYPE = {
  // 獲得条件（案件名の下に表示する短い文）
  CV_CONDITION: 1,
  // 獲得時間
  CV_TIMING: 3,
  // 獲得条件・注意事項
  POINTACQUIRE_CONDITION: 5,
  // サービス詳細
  SPONSOR_DETAIL: 6,
} as const;

export type DescriptionType = typeof DESCRIPTION_TYPE[keyof typeof DESCRIPTION_TYPE];
