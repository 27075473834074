import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LocationDescriptorObject } from "history";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import clsx from "clsx";

interface Props {
  hide: boolean;
  className?: string;
  push?: LocationDescriptorObject;
}

export const BackIcon: React.FC<Props> = ({ hide, className, push }) => {
  const { location } = useSelector(selectRouter);
  const history = useHistory();

  const backToIndex = useCallback(() => {
    if (push) {
      history.push(push);
    } else {
      history.goBack();
    }
  }, [history, location]);

  useEffect(() => {
    const listen = history.listen((l, action) => {
      if (action === "POP") {
        history.replace({
          pathname: history.location.pathname,
          search: history.location.search,
          state: { ...location.state, referrer: location },
        });
      }
    });
    return () => listen();
  }, []);

  return (
    <>
      {hide ? null : (
        <ArrowBackIos
          className={clsx(CommonStyles.materialIcon, className)}
          viewBox="-6 0 24 24"
          onClick={backToIndex}
          data-testid="backIcon"
        />
      )}
    </>
  );
};
