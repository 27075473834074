import { Expose, Type } from "class-transformer";
import { popupOfferConversionPoints } from "models";

export class popupOffer {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "imageUrl" })
  imageUrl: string;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "advertisementId" })
  advertisementId?: number;

  @Expose({ name: "linkUrl" })
  linkUrl?: string;

  @Expose({ name: "popupBannerUrl" })
  popupBannerUrl?: string;

  @Expose({ name: "popupText" })
  popupText?: string;

  @Expose({ name: "popupButtonName" })
  popupButtonName?: string;

  @Expose({ name: "startAt" })
  startAt?: Date;

  @Expose({ name: "endAt" })
  endAt?: Date;

  @Expose({ name: "conversionPoints" })
  @Type(() => popupOfferConversionPoints)
  popupOfferConversionPoints?: popupOfferConversionPoints[];

  // ポップアップ表示案件のセール判定
  get isSale(): boolean {
    return this.basePoint < this.actualPoint;
  }

  get isSur(): boolean {
    return this.popupOfferConversionPoints.length > 1;
  }

  get AchievementPointName(): string {
    return this.popupOfferConversionPoints[0]?.name;
  }
}
