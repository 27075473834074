// サポートOS引き下げ対応
import "polyfills.js";
import "intersection-observer";

import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";

import TagManager, { TagManagerArgs } from "react-gtm-module";

// CSSは当面ここで全部読み込む
import "./stylesheets/app.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import datadog from "modules/datadog";
import ReactModal from "react-modal";

import ErrorBoundary from "components/common/ErrorBoundary";

datadog.logs.init();

const tagManagerArgs: TagManagerArgs = {
  gtmId: process?.env?.REACT_APP_GTM_CONTAINER_ID as string,
};

TagManager.initialize(tagManagerArgs);

const root = createRoot(document.getElementById("root") as HTMLElement);
ReactModal.setAppElement(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
