export default {
  type: {
    INDEX: 1,
    CHALLENGE: 2,
    WAITING: 3,
    HISTORY: 4,
    PRE_REGIST: 5,
    CONTACT: 6,
  },
  page: {
    INDEX: "index",
    CHALLENGE: "challenge",
    WAITING: "waiting",
    HISTORY: "history",
    PRE_REGIST: "preRegist",
    CONTACT: "contact",
  },
};

export const DisplayTabType = {
  Undefined: 0,
  BigImage: 1,
  Regular: 2,
  Survey: 3,
  FeatureBox: 4,
  FeatureIcon: 5,
  FeatureSmallBox: 6,
};
