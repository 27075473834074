import { Menus } from "models";
import { DISPLAY_TYPE } from "utiles/menu";
/**
 * メニュー表示内容を判別する関数
 * @param menus 広告データ
 * @return メニュー表示内容が格納された配列
 */
export const filterMenus = (menus: Menus[]) => {
  // クエリ取得
  const query = new URLSearchParams(location.search);
  // suid以降の値を取得
  const suid = query.get("suid");

  const displayMenus: Menus[] = menus.filter(
    (data) =>
      data.displayType === DISPLAY_TYPE.ALWAYS_SHOW ||
      (data.displayType === DISPLAY_TYPE.SUID_DEPENDENT_SHOW && suid) ||
      (data.displayType === DISPLAY_TYPE.HIDE && !suid)
  );

  return displayMenus;
};
