import { Expose, Type } from "class-transformer";
import { Images, Descriptions } from "models";
import { DESCRIPTION_TYPE } from "types/DescriptionType";
import { IMAGE_TYPE } from "types/ImageType";
import { ChallengeConversionPoints } from "./challengeConversionPoints";
import { UserOffer } from "./userOffer";

export class ChallengeOffer {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline?: string;

  @Expose({ name: "actionUrlPrefix" })
  actionUrlPrefix?: string;

  @Expose({ name: "actionUrl" })
  actionUrl?: string;

  @Expose({ name: "displayName" })
  displayName: string;

  @Expose({ name: "isFree" })
  isFree: boolean;

  @Expose({ name: "isEasy" })
  isEasy: boolean;

  @Expose({ name: "isPreRegist" })
  isPreRegist: boolean;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Expose({ name: "descriptions" })
  descriptions: Descriptions[];

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  @Type(() => ChallengeConversionPoints)
  conversionPoints?: ChallengeConversionPoints[];

  @Type(() => Images)
  images?: Images[];

  @Type(() => UserOffer)
  userOffer: UserOffer;

  get cvCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_CONDITION)?.text;
  }

  get iconUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.ICON_URL)?.url;
  }

  get listImageUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.LISTIMAGE_URL)?.url;
  }

  get isSur() {
    return this.conversionPoints?.length > 1;
  }

  get pointAcquireCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.POINTACQUIRE_CONDITION)
      ?.text;
  }

  get sponsorDetail() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.SPONSOR_DETAIL)?.text;
  }
}
