import { Expose } from "class-transformer";

export class TopSurvey {
  @Expose({ name: "displayName" })
  displayName: string;

  @Expose({ name: "changeProfileUrl" })
  changeProfileUrl: string;

  @Expose({ name: "contactUrl" })
  contactUrl: string;
}
