import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";

// stateの型
export interface ServiceType {
  serviceType: number;
}

export const ServiceTypeState: ServiceType = {
  serviceType: Number(session("serviceType")),
};

const ServiceTypeModules = createSlice({
  name: "ServiceTypeModules",
  initialState: ServiceTypeState,
  reducers: {
    setServiceType: (state, action: PayloadAction<number>) => {
      setSession("serviceType", action.payload);
      return {
        ...state,
        serviceType: action.payload,
      };
    },
  },
});

export default ServiceTypeModules;
