import { Expose } from "class-transformer";

export class InquirySubmission {
  @Expose({ name: "legacyMode" })
  legacyMode: boolean;

  @Expose({ name: "id" })
  id: number;

  @Expose({ name: "inquirySubmissionType" })
  inquirySubmissionType: number;

  @Expose({ name: "title" })
  title: string;

  @Expose({ name: "detail" })
  detail: string;

  @Expose({ name: "imageUrl" })
  imageUrl?: string;
}
