import { Expose, Type } from "class-transformer";
import { ConversionPoints, Descriptions } from "models";
import { DESCRIPTION_TYPE } from "types/DescriptionType";

export class CampaignOffer {
  @Expose({ name: "id" })
  id: number;

  @Expose({ name: "title" })
  title: string;

  @Expose({ name: "imageUrl" })
  imageUrl: string;

  @Expose({ name: "description" })
  description: string;

  @Expose({ name: "isPreRegist" })
  isPreRegist: boolean;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Type(() => ConversionPoints)
  conversionPoints?: ConversionPoints[];

  @Expose({ name: "descriptions" })
  descriptions: Descriptions[];

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  @Expose({ name: "isVideo" })
  isVideo: boolean;

  get isSur() {
    return this.conversionPoints?.length > 1;
  }

  get cvCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_CONDITION)?.text;
  }
}
