import { useState } from "react";
import { offerApi } from "apis/offer";

export const useTutorialOffer = (tutorialOfferClickUrl: string) => {
  const [conversionUrl, setConversionUrl] = useState<string>();
  const [hasError, setError] = useState(false);
  const [isTutorialOfferClicked, setIsTutorialOfferClicked] = useState(false);
  const [isClickFetching, setIsClickFetching] = useState(false);

  const getTutorialOfferEventConversionUrl = () => {
    // API呼び出し前に状態をリセット
    setError(false);
    setIsClickFetching(true);

    offerApi
      .getTutorialOfferEventConversionUrl(tutorialOfferClickUrl)
      .then((result: string) => {
        setConversionUrl(result);
        setIsTutorialOfferClicked(true);
        setIsClickFetching(false);
      })
      .catch(() => {
        setError(true);
        setIsTutorialOfferClicked(false);
        setIsClickFetching(false);
      });
  };

  return {
    hasError,
    isTutorialOfferClicked,
    conversionUrl,
    isClickFetching,
    getTutorialOfferEventConversionUrl,
  };
};
