import { Offer } from "models";
import { CurrentOffers } from "interfaces/offers";
// 指定した数以下のものを選別
// eslint-disable-next-line arrow-body-style
export const getOffsetOffers = (offers: Offer[], offset: number): Offer[] => {
  return offers.filter((_, i) => i < offset);
};
/**
 * 初期表示の広告案件を選別する関数
 * @param offer 広告データ
 * @param offset 表示案件数
 * @return 初期表示の広告案件の配列
 */
export const offerInit = (offer: CurrentOffers[], offset: number) =>
  Object.entries(offer).map((data) => ({
    tabDisplayType: data[1].tabDisplayType,
    offers: getOffsetOffers(data[1].offers, offset),
  }));
