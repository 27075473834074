/**
 * 空データを削除した新しいオブジェクト生成のための関数
 * @param obj 広告データ
 * @return object
 */
export const removeEmpty = (obj: URLSearchParams): URLSearchParams =>
  new URLSearchParams(
    Object.entries(Object.fromEntries(obj))
      .map(([key, value]) => value && { [key]: value })
      .reduce((obj, data) => ({ ...obj, ...data }), {})
  );
