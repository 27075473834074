import React, { useEffect } from "react";
import { useIsSale } from "modules/useIsSale";
import Styles from "stylesheets/index/PointResult.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { formatComma } from "utiles/formatComma";
import clsx from "clsx";

interface SuperSale {
  startAt: Date;
  endAt: Date;
}

interface Color {
  appAreaTxtColor: string;
}

export interface Props {
  coinStyle: string;
  superSale: SuperSale;
  pointIconUrl: string;
  color: Color;
  basePoint: number;
  actualPoint: number;
  basePointMin: number;
  actualPointMin: number;
  isSur: boolean;
}

export const OthersSurveyPointResult = ({
  superSale,
  pointIconUrl,
  color,
  basePoint,
  actualPoint,
  basePointMin,
  actualPointMin,
  isSur,
  coinStyle,
}: Props) => {
  const [now, isInCurrencySale, confirmInCurrencySale, , , setDefault] = useIsSale();

  useEffect(() => {
    confirmInCurrencySale(superSale.startAt, superSale.endAt);
    return () => {
      setDefault();
    };
  }, [now]);

  const surPointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      isInCurrencySale && basePoint !== actualPoint ? (
        <>
          <span className={clsx(Styles.before, FontStyles.bold)}>
            {formatComma(basePointMin)}〜{formatComma(basePoint)}
          </span>
          {pointIconUrl && (
            <img className={clsx(coinStyle, Styles.icon)} src={pointIconUrl} alt="コイン" />
          )}
          <span className={clsx(Styles.after, FontStyles.bold)}>
            {formatComma(actualPointMin)}〜{formatComma(actualPoint)}
          </span>
        </>
      ) : (
        <>
          {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
          <div>
            <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
              {formatComma(basePointMin)}〜{formatComma(basePoint)}
            </span>
          </div>
        </>
      );

    return resultText;
  };

  const pointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      isInCurrencySale && basePoint !== actualPoint ? (
        <>
          <span className={clsx(Styles.before, FontStyles.bold)}>{formatComma(basePoint)}</span>
          {pointIconUrl && (
            <img className={clsx(coinStyle, Styles.icon)} src={pointIconUrl} alt="コイン" />
          )}
          <span className={clsx(Styles.after, FontStyles.bold)}>{formatComma(actualPoint)}</span>
        </>
      ) : (
        <>
          {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
          <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
            {formatComma(basePoint)}
          </span>
        </>
      );
    return resultText;
  };

  return (
    <div
      className={clsx(Styles.result, CommonStyles.right)}
      data-testid="result"
      style={{ color: color.appAreaTxtColor }}
    >
      {isSur ? surPointCalc() : pointCalc()}
    </div>
  );
};
