export const offerType = {
  MANGA: 6,
  PICCOMA_APP: 10,
  PICCOMA_WEB: 11,
  LINE_MANGA: 12,
  PICKUP: 13,
  TRIP_MILE: 14,
} as const;

export type offerwallType = typeof offerType[keyof typeof offerType];

export const AllOfferwallType = Object.values(offerType);
