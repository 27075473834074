import React from "react";
import Styles from "stylesheets/shared/Chips.module.scss";
import clsx from "clsx";

interface Props {
  text: string;
  style?: React.CSSProperties;
  type?: "primary" | "secondary";
  iconUrl?: string;
}

export const Chips: React.FC<Props> = ({ text, style, type = "primary", iconUrl }) => (
  <div
    style={style}
    className={clsx(
      Styles.root,
      Styles.centeredContent,
      type === "primary" ? Styles.primary : type === "secondary" ? Styles.secondary : ""
    )}
  >
    {iconUrl ? (
      <img className={Styles.text} src={iconUrl} alt="ビデオ" />
    ) : (
      <span className={Styles.text}> {text}</span>
    )}
  </div>
);
