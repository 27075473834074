import { TutorialBanner } from "models/tutorialBanner";
/**
 * ブラウザにデータを保存する関数
 * @param key 保存するデータのキー
 * @param value 保存するデータ
 * @return データ保存
 */
export const setSession = (key: string, value: string | boolean | number | TutorialBanner[]) =>
  sessionStorage.setItem(key, String(value));
/**
 * ブラウザからデータを取得する関数
 * @param key 取得データのキー
 * @return データ取得
 */
export const session = (key: string) => sessionStorage.getItem(key);
