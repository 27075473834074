import { Expose } from "class-transformer";

export class popupOfferConversionPoints {
  @Expose({ name: "step" })
  step: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "saleType" })
  saleType: number;

  @Expose({ name: "saleAmount" })
  saleAmount: number;

  @Expose({ name: "saleStartAt" })
  saleStartAt: string;

  @Expose({ name: "saleEndAt" })
  saleEndAt: string;
}
