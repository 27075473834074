import React from "react";
import Styles from "stylesheets/shared/OfferIcon.module.scss";
import { Link } from "react-router-dom";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { disassemblyPath } from "modules/url";
import clsx from "clsx";

interface Props {
  src: string;
  isIndex?: boolean;
  to?: string;
}

export const OfferIcon: React.FC<Props> = (props) => {
  // 基本的にトップページ以外で呼ぶことが多いので初期値をfalseにしています
  const { isIndex = false, to } = props;
  const { location } = useSelector(selectRouter);

  return (
    props.src && (
      <div
        className={clsx(Styles.container, isIndex && Styles["is-index"])}
        data-testid="offerIcon"
      >
        {to ? (
          <Link
            to={{
              ...disassemblyPath(to),
              state: { display: location.state?.display, referrer: location },
            }}
          >
            <img src={props.src} alt="アイコン" />
          </Link>
        ) : (
          <img src={props.src} alt="アイコン" />
        )}
      </div>
    )
  );
};
