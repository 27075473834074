import { Expose } from "class-transformer";

export class Banners {
  @Expose({ name: "imageUrl" })
  imageUrl: string;

  @Expose({ name: "linkUrl" })
  linkUrl: string;

  @Expose({ name: "bannerType" })
  bannerType: number;

  order: number;
}
