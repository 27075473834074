import { Expose } from "class-transformer";

export class History {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline: string;

  @Expose({ name: "receivedPoint" })
  receivedPoint: number;

  @Expose({ name: "cvCondition" })
  cvCondition: string;

  @Expose({ name: "cvEstimatedTime" })
  cvEstimatedTime: string;

  @Expose({ name: "isApproved" })
  isApproved: boolean;

  @Expose({ name: "displayAppName" })
  displayAppName: string;

  @Expose({ name: "iconUrl" })
  iconUrl: string;

  @Expose({ name: "listImageUrl" })
  listImageUrl: string;

  @Expose({ name: "registeredAt" })
  registeredAt: string;

  @Expose({ name: "approvedDate" })
  approvedDate: string;

  @Expose({ name: "challengedDate" })
  challengedDate: string;

  @Expose({ name: "surveyId" })
  surveyId?: string;
}
