import React from "react";
import { Survey } from "models";
import { SurveyListItem } from "SkyflagFrontendOfferwall/components/index/SurveyListItem";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/SurveyList.module.scss";
import { useSurvey } from "hooks/useSurvey";
import { Loading } from "components/shared/Loading";
import AttentionMark from "images/attention.png";
import { offerwallType } from "types/offerwallType";
import { SurveyTermsUrl } from "utiles/survey";

interface Color {
  appAreaBgColor: string;
  expointBgColor: string;
  expointTxtColor: string;
  mcvBgColor: string;
  mcvTxtColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}

interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

interface Search {
  linkFrom?: string;
  tab?: string;
}
export interface Props {
  color: Color;
  pointIconUrl: string;
  pointUnit: string;
  superSale: SuperSale;
  search: Search;
  offerwallType: offerwallType;
}

export const SurveyList = React.memo(
  ({ color, pointIconUrl, pointUnit, superSale, search, offerwallType }: Props) => {
    const [, isSurveyLoaded, surveyData] = useSurvey();

    if (!isSurveyLoaded) {
      return <Loading />;
    }
    return (
      <div className={Styles.surveyList} id="offerList">
        <div className={Styles.attention}>
          <img src={AttentionMark} />
          <div className={Styles.attention_text}>
            <div>
              獲得できる{pointUnit}数は回答画面に表示されるものでなくこちらに表示されている
              {pointUnit}
              数になります。
            </div>
            <div>
              <a
                className={Styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={SurveyTermsUrl}
              >
                利用規約
              </a>
              への同意のうえ参加をお願いします。
            </div>
          </div>
        </div>
        {/* 未回答アンケート */}
        {surveyData
          ?.filter((surveyData) => surveyData.situation === "未回答")
          .map((surveyData: Survey, i: number) => (
            <a
              href={`${surveyData.redirectSt}&position=${String(i + 1)}&tab=${
                search.tab
              }&linkfrom=${search.linkFrom}`}
              target="_blank"
              rel="noreferrer"
              key={i}
            >
              <SurveyListItem
                displayName={surveyData.title}
                cvCondition="アンケート回答"
                basePoint={surveyData.basePoint}
                actualPoint={surveyData.actualPoint}
                basePointMin={surveyData.basePointMin}
                actualPointMin={surveyData.actualPointMin}
                color={color}
                pointIconUrl={pointIconUrl}
                pointUnit={pointUnit}
                superSale={superSale}
                situation={surveyData.situation}
                researchId={surveyData.researchId}
                iconUrl={surveyData.iconUrl}
                company={surveyData.company}
                offerwallType={offerwallType}
              />
            </a>
          ))}
        {surveyData?.filter((surveyData) => surveyData.situation === "未回答").length === 0 && (
          // 回答可能アンケートが0の場合の表示
          <div style={{ backgroundColor: color.appAreaBgColor, textAlign: "center" }}>
            現在回答可能なアンケートがありません
          </div>
        )}
        {/* 回答済み、期限切れアンケート */}
        <div style={{ color: color.cvConditionColor, textAlign: "center" }}>
          回答済み・期限切れアンケート
        </div>
        {surveyData
          ?.filter((surveyData) => surveyData.situation !== "未回答")
          .map((surveyData: Survey, i: number) => (
            <div className={Styles.unableToAnswersurveyList}>
              <SurveyListItem
                displayName={surveyData.title}
                cvCondition="アンケート回答"
                basePoint={surveyData.basePoint}
                actualPoint={surveyData.actualPoint}
                basePointMin={surveyData.basePointMin}
                actualPointMin={surveyData.actualPointMin}
                color={color}
                pointIconUrl={pointIconUrl}
                pointUnit={pointUnit}
                superSale={superSale}
                situation={surveyData.situation}
                researchId={surveyData.researchId}
                key={i}
                iconUrl={surveyData.iconUrl}
                company={surveyData.company}
                offerwallType={offerwallType}
              />
            </div>
          ))}
      </div>
    );
  }
);
