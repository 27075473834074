import React, { useState, useEffect } from "react";

interface Props {
  startAt?: Date;
  endAt?: Date;
  format?: string;
}

const Countdown: React.FC<Props> = (props) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    if (!props.startAt || now < props.startAt) return null;
    const diff = props.endAt ? new Date(props.endAt).getTime() - now.getTime() : 0;
    if (diff <= 0) return null;

    return {
      hours: Math.floor(diff / 1000 / 60 / 60),
      minutes: Math.floor(diff / 1000 / 60) % 60,
      seconds: Math.floor(diff / 1000) % 60,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (timeLeft?: { hours: number; minutes: number; seconds: number } | null) => {
    if (!timeLeft) return "00:00:00";
    return `${timeLeft.hours.toString().padStart(2, "0")}:${timeLeft.minutes
      .toString()
      .padStart(2, "0")}:${timeLeft.seconds.toString().padStart(2, "0")}`;
  };

  const renderTime = () =>
    props.format ? props.format.replace("{timer}", formatTime(timeLeft)) : formatTime(timeLeft);

  return <>{renderTime()}</>;
};

export default Countdown;
