import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { topChallenging, Offer, popup, Pickup } from "models";
import { offerApi } from "apis/offer";
import { Media } from "./media";
import { TutorialBanner } from "models/tutorialBanner";
import { TopSurvey } from "models/topSurvey";

export interface Tabs {
  offerIds: number[];
  tab: number;
  name: string;
  tabDisplayType: number;
}
export interface Offers {
  offers: Offer[];
  tabs: Tabs[];
  challenge: topChallenging[];
  popups: popup[];
  surveys: TopSurvey[];
  pickups: Pickup[];
  media: Media;
  isTabsScroll: boolean;
  tutorialHeaderImage: string | null;
  tutorialBanners: TutorialBanner[];
  superSaleBannerImage: string | null;
}
export interface OfferwallTop extends Offers {
  isError: boolean;
  isLoaded: boolean;
}

export const OfferwallState: OfferwallTop = {
  offers: [],
  tabs: [],
  challenge: [],
  popups: [],
  surveys: [],
  pickups: [],
  media: undefined,
  isTabsScroll: false,
  tutorialHeaderImage: null,
  tutorialBanners: [],
  superSaleBannerImage: null,
  isError: false,
  isLoaded: false,
};

export const fetchAsyncGetTop = createAsyncThunk(
  "offerwall/top",
  async (request: {
    owp: string;
    suid: string;
    spram1: string;
    spram2: string;
    display_offer_type: number;
    sad: string;
    serv: string;
    close_link: string;
  }) => {
    const data = (await offerApi.getOffers(
      request.owp,
      request.suid,
      request.spram1,
      request.spram2,
      request.display_offer_type,
      request.sad,
      request.serv,
      request.close_link
    )) as Offers;
    return data;
  }
);
const OfferwallTopModules = createSlice({
  name: "OfferwallTopModules",
  initialState: OfferwallState,
  reducers: {
    setIsError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isError: action.payload,
    }),
    setIsLoaded: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoaded: action.payload,
    }),
    setChallenge: (state, action: PayloadAction<topChallenging[]>) => ({
      ...state,
      challenge: action.payload,
    }),
  },
  extraReducers: (builder) => {
    // top
    builder.addCase(fetchAsyncGetTop.pending, (state) => ({
      // 非同期処理中
      ...state,
      isLoaded: false,
    }));
    builder.addCase(fetchAsyncGetTop.fulfilled, (state, action: PayloadAction<Offers>) => {
      const {
        offers,
        tabs,
        challenge,
        media,
        popups,
        surveys,
        pickups,
        isTabsScroll,
        tutorialHeaderImage,
        tutorialBanners,
        superSaleBannerImage,
      } = action.payload;
      return {
        // 非同期処理成功時
        ...state,
        isLoaded: true,
        isError: false,
        offers,
        tabs,
        media,
        challenge,
        popups,
        surveys,
        pickups,
        isTabsScroll,
        tutorialHeaderImage,
        tutorialBanners,
        superSaleBannerImage,
      };
    });
    builder.addCase(fetchAsyncGetTop.rejected, (state) => ({
      // 非同期処理失敗時
      ...state,
      isLoaded: true,
      isError: true,
    }));
  },
});

export default OfferwallTopModules;
