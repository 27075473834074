import React from "react";
import stylesResponsive from "stylesheets/common/Button.module.scss";
import stylesPortrait from "stylesheets/common/ButtonPortrait.module.scss";
import clsx from "clsx";

interface Color {
  bgColor?: string;
  txtColor?: string;
}

export interface Props {
  title: string | React.ReactNode;
  className?: string;
  color: Color;
  onClick?: () => void;
  tag?: "a" | "button" | "submit" | "input";
  type?: "primary" | "secondary" | "tertiary";
  isDisabled?: boolean;
  href?: string;
  isPortraitFixed?: boolean;
  isTutorialOffer?: boolean;
}

export const Button = ({
  title,
  color,
  className,
  onClick,
  tag = "button",
  type = "primary",
  isDisabled,
  href,
  isPortraitFixed,
  isTutorialOffer = false,
}: Props) => {
  const styles = isPortraitFixed ? stylesPortrait : stylesResponsive;

  if (isTutorialOffer) {
    return (
      <button
        className={clsx(styles.secondary, styles.button, styles.link, className)}
        style={{
          pointerEvents: isDisabled ? "none" : "auto",
          backgroundColor: color.bgColor,
          color: color.txtColor,
        }}
        onClick={onClick}
        data-testid="dummyButton"
      >
        {title}
      </button>
    );
  }

  return type === "primary" && tag === "button" ? (
    <button
      style={{ backgroundColor: color.bgColor, color: color.txtColor }}
      className={clsx(styles.primary, className)}
      data-testid="btn"
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "button" ? (
    <button
      style={{
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      className={clsx(styles.secondary, styles.button)}
      data-testid="btn"
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "submit" ? (
    <button
      style={{
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      className={clsx(styles.secondary, styles.button)}
      data-testid="btn"
      type="submit"
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  ) : type === "secondary" && tag === "a" ? (
    <a
      href={href}
      className={clsx(styles.secondary, styles.button, styles.link, className)}
      target="_blank"
      rel="noreferrer"
      style={{
        pointerEvents: isDisabled ? "none" : "auto",
        backgroundColor: color.bgColor,
        color: color.txtColor,
      }}
      onClick={onClick}
      data-testid="btn"
    >
      {title}
    </a>
  ) : type === "tertiary" && tag === "button" ? (
    <button
      onClick={onClick}
      className={styles.tertiary}
      style={{
        backgroundColor: color.bgColor ?? "#033068",
        color: color.txtColor ?? "#fff",
      }}
    >
      {title}
    </button>
  ) : (
    <></>
  );
};
