import { Expose } from "class-transformer";
import { MenuType } from "types/MenuType";
import { DisplayType } from "utiles/menu";

export class Menus {
  title: string;

  @Expose({ name: "linkUrl" })
  linkUrl: string;

  @Expose({ name: "displayType" })
  displayType: DisplayType;

  @Expose({ name: "menuType" })
  menuType: MenuType;
}
