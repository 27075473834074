import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSession, session } from "modules/session";
import { TutorialBanner } from "models/tutorialBanner";

// stateの型
export interface TutorialBanners {
  tutorialBanners: TutorialBanner[];
}

const tutorialBannersFromSession: string | TutorialBanner[] = session("tutorialBanners");
const initialTutorialBanners: TutorialBanner[] = Array.isArray(tutorialBannersFromSession)
  ? tutorialBannersFromSession
  : [];

export const TutorialBannersState: TutorialBanners = {
  tutorialBanners: initialTutorialBanners,
};

const TutorialBannersModules = createSlice({
  name: "TutorialBannersModules",
  initialState: TutorialBannersState,
  reducers: {
    setTutorialBanners: (state, action: PayloadAction<TutorialBanner[]>) => {
      setSession("tutorialBanners", action.payload);
      return {
        ...state,
        tutorialBanners: action.payload,
      };
    },
  },
});

export default TutorialBannersModules;
