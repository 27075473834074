import { useMemo, useCallback, useLayoutEffect } from "react";
import { Offer, Media, topChallenging, popup, Pickup } from "models";
import { plainToClass } from "class-transformer";
import { selectMediaState, selectOfferwallState, selectDisplayIndexState } from "store/selector";
import { useSelector, useDispatch } from "react-redux";
import { dummyApi } from "apis/dummyApi";
import { CurrentOffers as OfferWallTop, PickupOffers } from "interfaces/offers";
import OfferwallTopModules, { fetchAsyncGetTop, Tabs } from "slices/offerwall";
import PopupModules from "slices/popup";
import { TutorialBanner } from "models/tutorialBanner";
import TutorialBannersModules from "slices/tutorialBanners";
import TutorialHeaderImageModules from "slices/tutorialHeaderImage";
import { TopSurvey } from "models/topSurvey";

export const useOffers = (): [
  boolean,
  boolean,
  OfferWallTop[] | undefined,
  Offer[] | undefined,
  (offset: number) => Promise<Offer[]>,
  Media | undefined,
  Tabs[] | undefined,
  topChallenging[] | undefined,
  popup[] | undefined,
  TopSurvey[] | undefined,
  PickupOffers[] | undefined,
  boolean,
  (offset: number, index: number) => Promise<Offer[]>,
  string,
  TutorialBanner[]
] => {
  const dispatch = useDispatch();
  const media = useSelector(selectMediaState);
  const {
    challenge,
    offers,
    isError,
    isLoaded,
    tabs,
    popups,
    surveys,
    pickups,
    isTabsScroll,
    tutorialHeaderImage,
    tutorialBanners,
  } = useSelector(selectOfferwallState);
  const { displayIndex } = useSelector(selectDisplayIndexState);
  const queries = new URLSearchParams(window.location.search);
  const tab = queries.get("tab") ?? 1;
  const suid = queries.get("suid") ?? "";
  const _owp = queries.get("_owp") ?? "";
  const spram1 = queries.get("spram1") ?? "";
  const spram2 = queries.get("spram2") ?? "";
  const displayOfferType = parseInt(queries.get("display_offer_type") ?? "", 10);
  const displayOfferTypeToNumber = !isNaN(displayOfferType) ? displayOfferType : 0;
  const sad = queries.get("sad") ?? "";
  const serv = queries.get("serv") ?? "";
  const closeLink = queries.get("close_link") ?? "";

  const setIsError = useCallback(
    (values) => dispatch(OfferwallTopModules.actions.setIsError(values)),
    [dispatch]
  );

  const setOfferAllTop = useCallback(() => {
    dispatch(
      fetchAsyncGetTop({
        owp: _owp,
        suid,
        spram1,
        spram2,
        display_offer_type: displayOfferTypeToNumber,
        sad,
        serv,
        close_link: closeLink,
      })
    );
  }, [dispatch]);

  const mediaData: Media = useMemo(() => plainToClass(Media, media), [media]);

  const tabsData: Tabs[] = useMemo(() => tabs, [tabs]);

  const offersData: Offer[] = useMemo(
    () => offers?.map((data) => plainToClass(Offer, data)),
    [offers]
  );

  const popupData: popup[] = useMemo(
    () => popups?.map((data) => plainToClass(popup, data)),
    [offers]
  );

  const surveysData = useMemo(
    () => surveys?.map((data) => plainToClass(TopSurvey, data)),
    [surveys]
  );

  const pickupsData: Pickup[] = useMemo(
    () => pickups?.map((data) => plainToClass(Pickup, data)),
    [pickups]
  );

  const offersWallToppickups: { [key: string]: number[] } = useMemo(
    () =>
      pickupsData
        ? Object.entries(pickupsData)
            .map(([, value]) => [value.name, value.offerIds])
            .reduce((obj, [key, val]) => ({ ...obj, [key as string]: val }), {})
        : {},
    [pickupsData]
  );

  const offersWallTop: OfferWallTop[] = useMemo(
    () =>
      tabsData
        ? Object.entries(tabsData).map(([, values]) => ({
            tabDisplayType: values.tabDisplayType,
            offers: values.offerIds?.map((id) => offersData?.find((data) => data.id === id)),
          }))
        : [],
    [tabsData, offersData]
  );

  const PickupOffers: PickupOffers[] = useMemo(
    () =>
      offersWallToppickups
        ? Object.entries(offersWallToppickups).map(([name, values]) => ({
            name,
            offers: values?.map((id) => offersData?.find((data) => data.id === id)),
          }))
        : [],
    [pickupsData, offersData]
  );

  const challengeData = useMemo(
    () => challenge?.map((data) => plainToClass(topChallenging, data)),
    [challenge]
  );

  const fetchPaginate = useCallback(
    async (offset: number): Promise<Offer[]> => {
      const data: Offer[] = offersWallTop[displayIndex]?.offers;
      const res: Offer[] = await dummyApi.getPagenationToOffer(data, offset);
      return res;
    },
    [offersWallTop, tab, displayIndex]
  );

  const PickupsFetchPaginate = useCallback(
    async (offset: number, index: number): Promise<Offer[]> => {
      const data: Offer[] = PickupOffers[index]?.offers;
      const res: Offer[] = await dummyApi.getPagenationToOffer(data, offset);
      return res;
    },
    [PickupOffers]
  );

  useLayoutEffect(() => {
    dispatch(TutorialBannersModules.actions.setTutorialBanners(tutorialBanners));
    dispatch(TutorialHeaderImageModules.actions.setTutorialHeaderImage(tutorialHeaderImage));
  }, [mediaData]);

  useLayoutEffect(() => {
    // 未CV関連機能のUIを調整するために、OWTOP遷移時にAPIを叩く
    setOfferAllTop();
    dispatch(PopupModules.actions.setIsPopup(false));
  }, [dispatch]);

  useLayoutEffect(() => {
    if (!_owp) {
      setIsError(true);
      (() => [true, true, [], [], fetchPaginate, null, []])();
    }
  }, [_owp]);

  useLayoutEffect(() => {
    // ピッコマ未ログイン時リダイレクト
    if (mediaData?.isPiccomaWebNotLogin) {
      window.location.replace(mediaData?.piccomaWebUrl);
    }
  }, [mediaData]);

  // muidがないときは空データを返す
  if (suid === null)
    return [
      null,
      true,
      [],
      [],
      fetchPaginate,
      null,
      [],
      [],
      [],
      [],
      [],
      false,
      PickupsFetchPaginate,
      null,
      [],
    ];
  return [
    isError,
    isLoaded,
    offersWallTop,
    offersData,
    fetchPaginate,
    mediaData,
    tabsData,
    challengeData,
    popupData,
    surveysData,
    PickupOffers,
    isTabsScroll,
    PickupsFetchPaginate,
    tutorialHeaderImage,
    tutorialBanners,
  ];
};
