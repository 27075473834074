import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// stateの型
export interface Modal {
  isActive: boolean;
}

export const ModalState: Modal = {
  isActive: true,
};

const ModalModules = createSlice({
  name: "ModalModules",
  initialState: ModalState,
  reducers: {
    setIsActive: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isActive: action.payload,
    }),
  },
});

export default ModalModules;
