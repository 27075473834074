import { Expose } from "class-transformer";
import { DescriptionType } from "types/DescriptionType";

export class Descriptions {
  @Expose({ name: "text" })
  text: string;

  @Expose({ name: "type" })
  type: DescriptionType;
}
