import baseAxios from "./axios";

class MediaApi {
  async getMedia<T>(params: URLSearchParams, id?: string | number): Promise<T> {
    const res = await baseAxios.get<T>(`${process?.env?.REACT_APP_API}/media/owp/${id}`, {
      params,
    });
    return res.data;
  }
}

export const mediaApi = new MediaApi();
