import { Redirect } from "react-router-dom";
import queryModule from "modules/query";
import query from "utiles/query";
import { useRedicectToIndexOnSite } from "hooks/useRedicectToIndexOnSite";
import defaultPath from "utiles/path";
import { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { ERROR_PATTERN } from "components/common/ErrorBoundary";

export const RedirectToIndexRemoveQuery = ({ children, pathname, search, state }) => {
  const isRedirectQueryFronList = queryModule.isQuery({
    search: queryModule.object(search),
    list: [...query.remove.list, ...query.index.remove.list],
  });
  const isRedirectQueryFormObjList = queryModule.isQuery({
    search: queryModule.object(search),
    listInObj: query.index.remove.obj,
  });

  const removeFromList = queryModule.removeFromList({
    search: queryModule.object(search),
    removeList: [...query.remove.list, ...query.index.remove.list],
  });

  const filterFromObject = queryModule.filterFromObject({
    search: removeFromList,
    removeObj: query.index.remove.obj,
  });

  const params = queryModule.str(filterFromObject);

  return isRedirectQueryFronList || isRedirectQueryFormObjList ? (
    <Redirect
      to={{
        pathname: pathname.replace(/\/$/, ""),
        search: params,
        state,
      }}
    />
  ) : (
    children
  );
};

export const RedirectToRemoveSlash = ({ children, pathname, search, state }) => {
  const isSlash = pathname.match(/\/$/);
  return isSlash ? (
    <Redirect
      to={{
        pathname: pathname.replace(/\/$/, ""),
        search,
        state,
      }}
    />
  ) : (
    children
  );
};

export const RedirectLegacyToIndex = ({ search, state }) => (
  <Redirect
    to={{
      pathname: defaultPath.INDEX,
      search,
      state,
    }}
  />
);

export const RedirectToQueryConversion = ({ children, pathname, search, state }) => {
  const isConversionQuery = queryModule.isQuery({
    search: queryModule.object(search),
    obj: query.conversion,
  });

  const params = queryModule.str(
    queryModule.conversionObj({
      search: queryModule.object(search),
      obj: query.conversion,
    })
  );

  return isConversionQuery ? (
    <Redirect
      to={{
        pathname: pathname.replace(/\/$/, ""),
        search: params,
        state,
      }}
    />
  ) : (
    children
  );
};

export const RedirectToSiteQuery = ({ children }) => {
  const [error] = useRedicectToIndexOnSite();
  const handleError = useErrorHandler();
  if (error) {
    handleError(ERROR_PATTERN.API);
  }
  return <>{children}</>;
};

// ピッコマ特別対応のリダイレクト
export const RedirectToCampaign = ({ search, children }) => {
  const queries = new URLSearchParams(search);
  const offerParam = queries.get("offer") ?? "";
  const owpParam = queries.get("_owp") ?? "";
  const isCp = offerParam === "cp";

  // ピッコマへの特別対応のためのGUID
  const pikkomaGUID = {
    // 本番メディアID665
    IOS: "&placementGuid=6d201ec7-2fb9-4ad6-bb4d-e2322aefb186",
    // 本番メディアID666
    ANDROID: "&placementGuid=d1e40a58-64a8-4152-a701-dc4700fbdaed",
    // 本番メディアID836
    IOS_WEB: "&placementGuid=7b7d7080-2e6d-436a-bc46-844a37a36c03",
    // 本番メディアID837
    ANDROID_WEB: "&placementGuid=56484a3f-e899-4f5f-8883-b9e1db279e19",
    // STG用共通キャンペーンページ
    STG_CP: "&placementGuid=8f249a98-0762-4f98-b024-fecc45a07756",
  };
  // ピッコマへの特別対応のためのOWP
  const pikkomaOWP = {
    IOS: "k7YZHxkzidEz0QDfub2TKgAdMaGe3DAdMaGe3D",
    ANDROID: "lwuAdMaGe2BtAdMaGe2F2yfrx3mS2FTJpHWAAdMaGe3DAdMaGe3D",
    IOS_WEB: "XJ1pKAdMaGe2BlZALAdMaGe2BMC6Ec6qMbLwAdMaGe3DAdMaGe3D",
    ANDROID_WEB: "BanGzUh4Xp66hmZSpqMSgAAdMaGe3DAdMaGe3D",
    IOS_STG: "03tQJlQAdMaGe2BTK2oReMYVtAdMaGe2FNfwAdMaGe3DAdMaGe3D",
    ANDROID_STG: "kB4QTxU0AGAdMaGe2FZT0vvpTKTngAdMaGe3DAdMaGe3D",
    IOS_WEB_STG: "J3MjyXazF6HAdMaGe2Fo546SWjtLAAdMaGe3DAdMaGe3D",
    ANDROID_WEB_STG: "RQ5zBGHwMuQwESDLxyK1HAAdMaGe3DAdMaGe3D",
  };

  function switchGUID(owpParam: string) {
    switch (owpParam) {
      case pikkomaOWP.IOS:
        return pikkomaGUID.IOS;
      case pikkomaOWP.ANDROID:
        return pikkomaGUID.ANDROID;
      case pikkomaOWP.IOS_WEB:
        return pikkomaGUID.IOS_WEB;
      case pikkomaOWP.ANDROID_WEB:
        return pikkomaGUID.ANDROID_WEB;
      case pikkomaOWP.IOS_STG:
      case pikkomaOWP.IOS_WEB_STG:
      case pikkomaOWP.ANDROID_STG:
      case pikkomaOWP.ANDROID_WEB_STG:
        return pikkomaGUID.STG_CP;
      default:
        return null;
    }
  }

  if (isCp && switchGUID(owpParam)) {
    useEffect(() => {
      window.location.href = `https://ow.${
        switchGUID(owpParam) === pikkomaGUID.STG_CP ? "stg." : ""
      }skyflag.jp${
        defaultPath.CAMPAIGN + search.replace(/(&offer=cp)/, "") + switchGUID(owpParam)
      }`;
    }, []);
  }

  return children;
};
