import baseAxios from "./axios";

class OwpApi {
  async getOwp<T>(siteId: string): Promise<T> {
    const res = await baseAxios.get<T>(`${process?.env?.REACT_APP_API}/offerwall/siteIdToOwp`, {
      params: {
        siteId,
      },
    });
    return res.data;
  }
}

export const owpApi = new OwpApi();
