import { useState, useEffect } from "react";
/**
 * セール時の判定関数
 * @return 現在日時
 * @return スーパーセール判定
 * @return セール開始時間～セール終了時間が設定された時間内かの判断
 * @return タイムセール判定
 * @return セール開始時間～セール終了時間が設定された時間内かの判断(null許容)
 * @return confirmInTimeSaleとconfirmInCurrencySaleをfalseにする関数
 */
export const useIsSale = (): [
  Date,
  boolean,
  (start: Date, end: Date) => void,
  boolean,
  (start: Date, end: Date) => void,
  () => void
] => {
  const [now, setNow] = useState(new Date());
  const [isInTimeSale, setInTimeSale] = useState(false);
  const [isInCurrencySale, setInCurrencySale] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return function () {
      clearInterval(intervalId);
    };
  }, []);

  // セール開始からの時間と終了までの時間を計算して出力する関数
  const calcTime = (start: Date, end: Date): { afterBegin: number; beforeEnd: number } => {
    const currentTime: number = now.getTime();
    const saleStartTime: number = new Date(start).getTime();
    const saleEndTime: number = new Date(end).getTime();
    const afterBegin: number = currentTime - saleStartTime; // セール開始からの秒数
    const beforeEnd: number = saleEndTime - currentTime; // セール終了までの秒数
    return { afterBegin, beforeEnd };
  };
  // セール開始時間～セール終了時間が設定された時間内かを判断する関数
  const confirmInCurrencySale = (saleStart: Date, saleEnd: Date): void => {
    if (!saleStart || !saleEnd) setInCurrencySale(false); // 開始時刻または終了時刻が未設定であればfalse
    calcTime(saleStart, saleEnd).afterBegin > 0 && calcTime(saleStart, saleEnd).beforeEnd > 0
      ? setInCurrencySale(true)
      : setInCurrencySale(false); // 設定された時間内であればtrue
  };
  // セール開始時間～セール終了時間が設定された時間内かを判断する関数(null許容)
  const confirmInTimeSale = (saleStart: Date | null, saleEnd: Date | null): void => {
    if (!saleStart || !saleEnd) {
      setInTimeSale(false);
    } else {
      calcTime(saleStart!, saleEnd!).afterBegin > 0 && calcTime(saleStart!, saleEnd!).beforeEnd > 0
        ? setInTimeSale(true)
        : setInTimeSale(false); // 設定された時間内であればtrue
    }
  };
  // confirmInTimeSaleとconfirmInCurrencySaleをfalseにする関数
  const setDefault = () => {
    confirmInTimeSale(null, null);
    confirmInCurrencySale(null, null);
  };

  return [
    now,
    isInCurrencySale,
    confirmInCurrencySale,
    isInTimeSale,
    confirmInTimeSale,
    setDefault,
  ];
};
