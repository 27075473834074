import { Expose } from "class-transformer";
import { ImageType } from "types/ImageType";

export class Images {
  @Expose({ name: "type" })
  type: ImageType;

  @Expose({ name: "url" })
  url: string;
}
