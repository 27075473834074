import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// stateの型
export interface History {
  ids: { [key: string]: HistoryArray };
}

export const historyState: History = {
  ids: {},
};

type HistoryArray = (number | null)[]; // nullは入らない前提だが、万が一入った際に対応するためにnullを許容する

const localStorageNullExclusion = (key: string) => {
  const data: string | null = localStorage.getItem("persist:root");
  if (data) {
    const parsedData = JSON.parse(data);
    const historyData: History = JSON.parse(parsedData.history);
    const targetArray = historyData.ids[key];
    // 配列からnullを除去(nullが入るとエラーになる)
    const filteredArray = targetArray.filter((item) => item !== null);
    historyData.ids[key] = filteredArray;
    parsedData.history = JSON.stringify(historyData);

    // 更新したデータをローカルストレージに保存
    localStorage.setItem("persist:root", JSON.stringify(parsedData));
  }
};

const historyModules = createSlice({
  name: "history",
  initialState: historyState,
  reducers: {
    setIds: (state, action: PayloadAction<{ [key: string]: HistoryArray }>) => {
      const newState = { ...state, ids: {} };
      Object.keys(action.payload).forEach((key) => {
        // nullを除去してから最初の10項目を取得(nullが入るとエラーになる)
        const filteredArray = action.payload[key].filter((item) => item !== null).slice(0, 10);

        newState.ids[key] = filteredArray;

        // もとの配列とフィルター後の配列の長さが異なる場合、nullが削除されたとみなし、ローカルストレージのnullも削除する
        if (action.payload[key].length !== filteredArray.length) {
          localStorageNullExclusion(key);
        }
      });

      return newState;
    },
  },
});

export default historyModules;
