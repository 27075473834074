import { Expose, Type } from "class-transformer";
import { ConversionPoints, Images, Descriptions } from "models";
import { DESCRIPTION_TYPE } from "types/DescriptionType";
import { IMAGE_TYPE } from "types/ImageType";

export class BrowseHistoryOffers {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "displayName" })
  displayName?: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline?: string;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "isEasy" })
  isEasy: boolean;

  @Expose({ name: "isFree" })
  isFree: boolean;

  @Expose({ name: "isPreRegist" })
  isPreRegist: boolean;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Expose({ name: "actionUrl" })
  actionUrl?: string;

  @Expose({ name: "actionUrlPrefix" })
  actionUrlPrefix?: string;

  @Expose({ name: "descriptions" })
  descriptions: Descriptions[];

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  @Type(() => Images)
  images?: Images[];

  @Type(() => ConversionPoints)
  conversionPoints?: ConversionPoints[];

  get cvCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_CONDITION)?.text;
  }

  get iconUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.ICON_URL)?.url;
  }

  get listImageUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.LISTIMAGE_URL)?.url;
  }

  get isSur() {
    return this.conversionPoints?.length > 1;
  }
}
