import clsx from "clsx";
import React, { useLayoutEffect, useEffect, useRef, useState, useCallback } from "react";
import styles from "stylesheets/Slider.module.scss";

interface Props {
  childrenList: React.ReactNode[];
  index: number;
  tabNumbers: number[];
  className?: string;
  onSlideChange?: (id: number, index: number) => void;
  horizontalScroll?: boolean;
}

export const SwipeSlider = ({
  childrenList,
  index,
  tabNumbers,
  className,
  onSlideChange,
  horizontalScroll,
}: Props) => {
  const sliderRef = useRef(null);
  const innerRef = useRef(null);
  const [size, setSize] = useState<number>(0);
  const [id, setId] = useState<number>(0);
  const [isAnime, setIsAnime] = useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const isMountRef = useRef(false);
  const [swipe, setSwipe] = useState({
    startX: 0,
    startY: 0,
    moveX: 0,
  });
  const setWidth = useCallback(() => {
    const wrap = sliderRef.current;
    setSize(wrap.clientWidth * Number(childrenList.length));
  }, [size]);

  useLayoutEffect(() => {
    setWidth();
  }, [setWidth]);

  useLayoutEffect(() => {
    window.addEventListener("resize", setWidth);
    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [innerRef, sliderRef]);

  useEffect(() => {
    if (!isMountRef.current) {
      isMountRef.current = true;
    } else {
      onSlideChange(tabNumbers[id], id);
    }
  }, [id]);

  useLayoutEffect(() => {
    setId(index);
    if (isMountRef.current) {
      setIsAnime(true);
    }
  }, [index]);

  return (
    <div className={clsx(styles.root, className)} ref={sliderRef}>
      <div
        className={clsx(styles.track, "slider")}
        style={{
          width: `${size}px`,
          transform: `translate3d(-${
            sliderRef.current?.clientWidth * id
          }px, 0px, 0px) translate3d(${swipe.moveX}px, 0px, 0px)`,
          transition: isAnime ? ".2s" : "0s",
        }}
        ref={innerRef}
        onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => {
          const { clientX, clientY } = e.changedTouches[0];
          if (clientX < 0) return;
          setSwipe((prev) => ({ ...prev, startX: clientX, startY: clientY }));
        }}
        onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
          const { clientX, clientY } = e.changedTouches[0];
          const startX = clientX - swipe.startX;
          const startY = clientY - swipe.startY;

          if ((startX > 0 && id <= 0) || (startX < 0 && id >= childrenList.length - 1)) return;
          if (!horizontalScroll && startY < 50 && startY > -50 && (startX > 50 || startX < -50)) {
            setSwipe((prev) => ({ ...prev, moveX: startX }));
            setIsMouseOver(true);
            // スライド時に縦スクロールを禁止
            document.body.classList.add("is-fixed");
          }
        }}
        onTouchEnd={() => {
          setIsMouseOver(false);
          setSwipe((prev) => ({
            ...prev,
            startX: null,
          }));
          if (swipe.moveX > 40) {
            setId(id - 1);
            setIsAnime(true);
          }
          if (swipe.moveX < -40 && id < childrenList.length - 1) {
            setId(id + 1);
            setIsAnime(true);
          }
          setSwipe((prev) => ({
            ...prev,
            moveX: 0,
          }));
        }}
        onTransitionEnd={() => {
          setIsAnime(false);
        }}
      >
        {childrenList.map((elm, key) => (
          <div
            key={key}
            style={{ width: `${sliderRef.current?.clientWidth}px` }}
            className={clsx(isMouseOver && styles["is-mouse"])}
          >
            {elm}
          </div>
        ))}
      </div>
    </div>
  );
};
