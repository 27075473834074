import { Expose, Type } from "class-transformer";
import { Banners, Menus, Colors, SuperSales } from "./index";
import { offerType } from "utiles/media";
import { offerwallType } from "types/offerwallType";

export class OldMedia {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "backIconHidden" })
  backIconHidden: boolean;

  @Expose({ name: "clickMethod" })
  clickMethod?: string;

  @Expose({ name: "isCloseIconHidden" })
  isCloseIconHidden: boolean;

  @Expose({ name: "closeLink" })
  closeLink: string;

  @Expose({ name: "dlBtnNameApp" })
  dlBtnNameApp: string;

  @Expose({ name: "dlBtnNamePreRegist" })
  dlBtnNamePreRegist: string;

  @Expose({ name: "dlBtnNameWeb" })
  dlBtnNameWeb: string;

  @Expose({ name: "footerText" })
  footerText: string;

  @Expose({ name: "pointUnit" })
  pointUnit: string;

  @Expose({ name: "pointIconUrl" })
  pointIconUrl: string;

  @Expose({ name: "preRegistText" })
  preRegistText: string;

  @Expose({ name: "recommendIconUrl" })
  recommendIconUrl: string;

  @Expose({ name: "stepUpRewardText" })
  stepUpRewardText: string;

  @Expose({ name: "tabOtherName" })
  tabOtherName: string;

  @Expose({ name: "isIdfaRequired" })
  isIdfaRequired: boolean;

  @Expose({ name: "userPoint" })
  userPoint: number;

  @Expose({ name: "wallIconUrl" })
  wallIconUrl?: string;

  @Expose({ name: "wallTitle" })
  wallTitle?: string;

  @Expose({ name: "isWallTitleHidden" })
  isWallTitleHidden: boolean;

  @Expose({ name: "preRegistEmailAnnounce" })
  preRegistEmailAnnounce: string;

  @Expose({ name: "offerwallType" })
  offerwallType: offerwallType;

  @Expose({ name: "redirectType" })
  redirectType: number;

  @Expose({ name: "primarySuperSalePointMultiplier" })
  primarySuperSalePointMultiplier: number;

  @Expose({ name: "primarySuperSaleStartAt" })
  primarySuperSaleStartAt: Date;

  @Expose({ name: "primarySuperSaleEndAt" })
  primarySuperSaleEndAt: Date;

  @Expose({ name: "isBannerTabHidden" })
  isBannerTabHidden: string;

  @Expose({ name: "banners" })
  @Type(() => Banners)
  banners?: Banners[];

  @Expose({ name: "menus" })
  @Type(() => Menus)
  menus?: Menus[];

  @Expose({ name: "color" })
  @Type(() => Colors)
  colors: Colors;

  @Expose({ name: "superSales" })
  @Type(() => SuperSales)
  superSales?: SuperSales[];

  @Expose({ name: "googleAnalyticsTrackingId" })
  googleAnalyticsTrackingId?: string;

  @Expose({ name: "isSurvey" })
  isSurvey: boolean;

  @Expose({ name: "surveyContactUrl" })
  surveyContactUrl?: string;

  @Expose({ name: "changeMonitorAttributeUrl" })
  changeMonitorAttributeUrl?: string;

  @Expose({ name: "videoIconUrl" })
  videoIconUrl?: string;

  @Expose({ name: "privacyPolicyLinkUrl" })
  privacyPolicyLinkUrl?: string;

  @Expose({ name: "cookiePolicyLinkUrl" })
  cookiePolicyLinkUrl?: string;

  @Expose({ name: "anotherCustomerSupportEmailAddress" })
  anotherCustomerSupportEmailAddress?: string;

  get isArmadillo() {
    return this.offerwallType === offerType.MANGA;
  }

  get isBear() {
    return (
      this.offerwallType === offerType.PICCOMA_APP ||
      this.offerwallType === offerType.PICCOMA_WEB ||
      this.offerwallType === offerType.LINE_MANGA ||
      this.offerwallType === offerType.PICKUP
    );
  }

  get isIos() {
    const { platform, userAgent } = navigator;
    return (
      /iPad|iPhone|iPod|Mac/.test(userAgent) ||
      (platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  get isAndoroid() {
    const { userAgent } = navigator;
    return /android|windows/i.test(userAgent);
  }

  get isPiccomaWeb() {
    return this.offerwallType === offerType.PICCOMA_WEB;
  }

  get isPiccoma() {
    return (
      this.offerwallType === offerType.PICCOMA_APP || this.offerwallType === offerType.PICCOMA_WEB
    );
  }

  get isPiccomaWebNotLogin() {
    const { userAgent } = navigator;
    const queries = new URLSearchParams(window.location.search);
    const suid = queries.get("suid") ?? "";
    const ua = /iPhone|iPod|android.+Mobile/i.test(userAgent)
      ? "sp"
      : /iPad|android/i.test(userAgent)
      ? "tab"
      : "pc";

    return ua !== "sp" && this.isPiccomaWeb && !suid;
  }

  get piccomaWebUrl() {
    return "https://piccoma.com/web/notice_detail/2549";
  }

  // ピッコマ汎用化タイプ ピッコマタイプではあるがピッコマではない
  get isPiccomaTemplate() {
    return this.offerwallType === offerType.LINE_MANGA;
  }
}
