import defaultPath from "utiles/path";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import Styles from "stylesheets/index/OfferTile.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import { Link } from "react-router-dom";
import { linkFromType } from "types/linkFromType";
import { offerType, offerwallType } from "types/offerwallType";

interface Color {
  appAreaBgColor: string;
}

interface Search {
  position: number;
  linkFrom: linkFromType;
}

export interface Props {
  color: Color;
  BannerImage: string;
  search: Search;
  offerwallType?: offerwallType;
}

export const SurveyBanner = ({ color, BannerImage, search, offerwallType }: Props) => {
  const { location } = useSelector(selectRouter);
  const queries = new URLSearchParams(window.location.search);

  return (
    <>
      <div data-position={search.position}>
        <Link
          to={{
            pathname: `${defaultPath.INDEX}/survey`,
            search: new URLSearchParams({
              ...Object.fromEntries(queries.entries()),
              position: String(search.position),
              linkfrom: String(search.linkFrom),
            }).toString(),
            state: { previousPath: "", referrer: location },
          }}
          className={Styles.link}
        >
          {BannerImage && (
            <img
              src={BannerImage}
              alt="バナー"
              style={{ background: color.appAreaBgColor }}
              className={offerwallType !== offerType.MANGA && Styles.surveyBunnarImage}
            />
          )}
        </Link>
      </div>
    </>
  );
};
