import React, { useLayoutEffect } from "react";
import { selectMediaState, selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { Media } from "models";
import { plainToClass } from "class-transformer";
import { offerType } from "utiles/media";

interface Props {
  children: React.ReactNode;
}

const BodyFixed = ({ children }: Props) => {
  const mediaData = useSelector(selectMediaState);
  const { location } = useSelector(selectRouter);
  const media = plainToClass(Media, mediaData);
  const isTop = location.pathname === "/ad/p/ow/bear";

  useLayoutEffect(() => {
    if (media?.colors) {
      document.body.style.backgroundColor = media?.colors?.titleBgColor || "#fff";
    }

    if (isTop && media?.banners?.length === 0 && mediaData.offerwallType !== offerType.TRIP_MILE) {
      document.body.classList.add("is-fixed");
    } else {
      document.body.classList.remove("is-fixed");
    }
  }, [media, location]);

  return <>{children}</>;
};

export default BodyFixed;
