import React, { useEffect } from "react";
import { useIsSale } from "SkyflagFrontendOfferwall/modules/useIsSale";
import clockImg from "images/clock.png";
import Styles from "SkyflagFrontendOfferwall/stylesheets/shared/Clock.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";

type Props = {
  saleType: string;
  currencySaleBonus?: number;
  bgColor: string;
  txtColor: string;
  iconColor?: string;
  ratioColor?: string;
  opacity?: string | null;
  start: Date;
  end: Date;
};

export const Clock: React.FC<Props> = (props) => {
  // セール時間外ではnullを返すコンポーネントになる
  const [now, isInCurrencySale, , isInTimeSale, confirmInTimeSale, setDefault] = useIsSale();
  const { saleType, opacity = 0.9 } = props; // タイムセールかカレンシーセールか

  useEffect(() => {
    // 現在が更新される度にセール中かどうかを確認する
    confirmInTimeSale(props.start, props.end);
    return () => {
      // コンポーネントアンマウント時の処理
      setDefault();
    };
  }, [now]);

  if (!isInCurrencySale && !isInTimeSale) return null; // セール中でなければ非表示に

  // 残り時間表示
  const saleEnd: Date = new Date(props.end);
  const diff: number = saleEnd.getTime() - now.getTime();
  const sec = Math.floor(diff / 1000) % 60; // ミリ秒を秒に直す
  const min = Math.floor(diff / 1000 / 60) % 60;
  const hour = Math.floor(diff / 1000 / 60 / 60);
  const cBonus = props.currencySaleBonus; // カレンシーセールである場合の倍率

  return (
    <div
      className={clsx(
        Styles.container,
        CommonStyles.button,
        FontStyles.large,
        FontStyles.bold,
        saleType === "time" ? Styles.timeSale : Styles.currencySale
      )}
      style={{
        backgroundColor: props.bgColor,
        color: props.txtColor,
        opacity: !opacity ? 0.9 : Number(opacity),
      }}
    >
      {saleType === "time" ? (
        <img src={clockImg} alt="タイマー" className={Styles.clockIcon} />
      ) : (
        <div
          className={Styles.bonusIcon}
          style={{
            display: saleType === "time" ? "none" : "block",
            backgroundColor: props.iconColor,
            color: props.ratioColor,
          }}
        >
          <span>&times;{cBonus}</span>
        </div>
      )}
      <div className={Styles.timer}>
        <span className={Styles.timer_remainTime} data-testid="remainTime">
          残り時間&nbsp;{hour}&nbsp;時間&nbsp;{min}&nbsp;分&nbsp;{sec} 秒
        </span>
      </div>
    </div>
  );
};
