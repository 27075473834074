import React from "react";
import { ErrorBoundary as ThirdPartyErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

interface Props {
  children: React.ReactNode;
}

export const ERROR_PATTERN = {
  /** 通信エラー */
  API: "ApiError",
} as const;

export default function ErrorBoundary({ children }: Props) {
  return (
    <ThirdPartyErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      {children}
    </ThirdPartyErrorBoundary>
  );
}
