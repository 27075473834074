import React from "react";
import { OfferIcon } from "SkyflagFrontendOfferwall/components/shared/OfferIcon";
import { Clock } from "SkyflagFrontendOfferwall/components/shared/Clock";
import {
  PointResult,
  Props as PointResultProps,
} from "SkyflagFrontendOfferwall/components/index/PointResult";
import { Button } from "SkyflagFrontendOfferwall/components/common/Button";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/OffersListItemIcon.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";
import CalendarMark from "SkyflagFrontendOfferwall/images/calendar.png";
import CoinMark from "SkyflagFrontendOfferwall/images/coin.png";
import CoinMarkGray from "SkyflagFrontendOfferwall/images/coin_gray.png";
import beginnerMark from "SkyflagFrontendOfferwall/images/beginner.png";
import beginnerMarkGray from "SkyflagFrontendOfferwall/images/beginner_gray.png";

interface Color {
  appAreaBgColor: string;
  expointBgColor: string;
  expointTxtColor: string;
  mcvBgColor: string;
  mcvTxtColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}

export interface Props extends PointResultProps {
  color: Color;
  surTxt: string;
  prTxt: string;
  recommendIconUrl: string;
  position: number;
  isRecommended: boolean;
  iconUrl: string;
  displayName: string;
  cvCondition: string;
  isPercentage: boolean;
  isEasy: boolean;
  isBulk: boolean;
  cvEstimatedTime: string;
  displayTabType: number;
}

export const OffersListItemIcon = React.memo(
  ({
    color,
    surTxt,
    prTxt,
    recommendIconUrl,
    position,
    iconUrl,
    isRecommended,
    displayName,
    cvCondition,
    isEasy,
    isBulk,
    cvEstimatedTime,
    displayTabType,
    ...pointResultProps
  }: Props) => (
    <div
      className={Styles.wrapper}
      style={{ backgroundColor: color.appAreaBgColor }}
      data-position={position}
    >
      <div className={Styles.clock}>
        {pointResultProps.timeSale?.point && (
          <Clock
            saleType="time"
            bgColor={color.expointBgColor}
            txtColor={color.expointTxtColor}
            start={pointResultProps.timeSale?.startAt}
            end={pointResultProps.timeSale?.endAt}
          />
        )}
      </div>
      <div className={Styles.offer}>
        <OfferIcon
          src={iconUrl}
          bgColor={color.mcvBgColor}
          txtColor={color.mcvTxtColor}
          isSUR={pointResultProps.isSur}
          surTxt={surTxt}
          prTxt={prTxt}
          displayTabType={displayTabType}
        />
        <div className={Styles.offer_content}>
          <div className={Styles.offer_content_upper}>
            <div className={Styles.info}>
              <h3 className={Styles.info_name} style={{ color: color.appAreaTxtColor }}>
                {displayName}
              </h3>
              <div
                className={clsx(CommonStyles.cvCondition, FontStyles.small)}
                style={{ color: color.cvConditionColor }}
              >
                {cvCondition}
              </div>
            </div>
            {isRecommended && recommendIconUrl && (
              <div className={Styles.recommendIcon}>
                <img src={recommendIconUrl} alt="オススメ" />
              </div>
            )}
          </div>
          <div className={Styles.borderOnly}> </div>
          <div className={Styles.featureArea}>
            {cvEstimatedTime && (
              <span className={Styles.featureBoxDays}>
                <img src={CalendarMark} alt="カレンダー" className={Styles.imgFeatureIcon} />
                <p className={Styles.featureText}>{cvEstimatedTime}</p>
              </span>
            )}
            {isBulk ? (
              <span className={Styles.featureBoxBulk}>
                <img src={CoinMark} alt="コイン" className={Styles.imgFeatureIcon} />
                <p className={Styles.featureText}>大量報酬</p>
              </span>
            ) : (
              <span className={Styles.featureBoxBulk}>
                <img src={CoinMarkGray} alt="コインなし" className={Styles.imgFeatureIcon} />
                <p className={Styles.featureText_not}>大量報酬</p>
              </span>
            )}
            {isEasy ? (
              <span className={Styles.featureBoxEasy}>
                <img src={beginnerMark} alt="初心者マーク" className={Styles.imgFeatureIcon} />
                <p className={Styles.featureText}>簡単</p>
              </span>
            ) : (
              <span className={Styles.featureBoxEasy}>
                <img
                  src={beginnerMarkGray}
                  alt="初心者マークなし"
                  className={Styles.imgFeatureIcon}
                />
                <p className={Styles.featureText_not}>簡単</p>
              </span>
            )}
            {!cvEstimatedTime && <span className={Styles.featureBoxFake} />}
          </div>
          <Button
            className={Styles.offer_content_button}
            title={<PointResult {...pointResultProps} />}
            color={{
              bgColor: color.dlBtnBgColor,
              txtColor: color.dlBtnTxtColor,
            }}
            tag="button"
            type="primary"
          />
        </div>
      </div>
    </div>
  )
);
