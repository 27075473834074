import React, { useEffect } from "react";
import { History } from "history";

interface Props {
  history: History;
  children: React.ReactNode;
}

const ScrollTop = ({ history, children }: Props) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      history.action !== "REPLACE" && window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

export default ScrollTop;
