import { useHistory } from "react-router-dom";
import Styles from "stylesheets/error/NotFound.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import defaultPath from "utiles/path";

interface NotFoundsProps {
  resetErrorBoundary: () => void;
}

const NotFound: React.FC<NotFoundsProps> = ({ resetErrorBoundary }) => {
  const history = useHistory();
  const handleBackButtonClick = () => {
    resetErrorBoundary();
    if (!history) {
      window.location.reload();
      return;
    }

    if (history.location?.state) {
      history.goBack();
    } else {
      history.push({
        pathname: defaultPath.INDEX,
        search: location.search,
        state: { referrer: location },
      });
    }
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <h1>ページが見つかりません</h1>
        <div>
          <p>
            大変恐れ入りますが、お探しのページは削除されている、もしくは一時的にアクセスできない可能性があります。
          </p>
          <br />
          <p>お時間をおいて再度お試しください。</p>
        </div>
        <button className={FontStyles.bold} onClick={handleBackButtonClick}>
          {history?.location?.state ? "一つ前に戻る" : "TOPへ戻る"}
        </button>
      </div>
    </div>
  );
};

export default NotFound;
