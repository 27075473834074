import { Expose, Type } from "class-transformer";
import { DetailConversionPoints, Descriptions, Images } from "models";
import { DESCRIPTION_TYPE } from "types/DescriptionType";
import { IMAGE_TYPE } from "types/ImageType";

export class DetailOffer {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "displayName" })
  displayName?: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline?: string;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "maxPoint" })
  maxPoint?: number;

  @Expose({ name: "isFree" })
  isFree: boolean;

  @Expose({ name: "isEasy" })
  isEasy: boolean;

  @Expose({ name: "isVideo" })
  isVideo: boolean;

  @Expose({ name: "videoUrl" })
  videoUrl?: string;

  @Expose({ name: "isVideoWatched" })
  isVideoWatched: boolean;

  @Expose({ name: "advertisementId" })
  advertisementId?: number;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Expose({ name: "actionUrl" })
  actionUrl?: string;

  @Expose({ name: "videoActionUrl" })
  videoActionUrl?: string;

  @Expose({ name: "redirectUrl" })
  redirectUrl?: string;

  @Expose({ name: "descriptions" })
  descriptions: Descriptions[];

  @Expose({ name: "isIdfaRequired" })
  isIdfaRequired: boolean;

  @Type(() => Images)
  images?: Images[];

  @Type(() => DetailConversionPoints)
  conversionPoints?: DetailConversionPoints[];

  @Expose({ name: "cvCategoryType" })
  cvCategoryType?: number;

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  @Expose({ name: "minPrice" })
  minPrice?: number;

  @Expose({ name: "hasRedirectUrl" })
  hasRedirectUrl: boolean;

  @Expose({ name: "isTutorialOffer" })
  isTutorialOffer: boolean;

  @Expose({ name: "tutorialOfferActionUrl" })
  tutorialOfferActionUrl: string;

  get isSur() {
    return this.conversionPoints?.length > 1;
  }

  get iconUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.ICON_URL)?.url;
  }

  get listImageUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.LISTIMAGE_URL)?.url;
  }

  get cvCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_CONDITION)?.text;
  }

  get cvTiming() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_TIMING)?.text;
  }

  get pointAcquireCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.POINTACQUIRE_CONDITION)
      ?.text;
  }

  get sponsorDetail() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.SPONSOR_DETAIL)?.text;
  }
}
