import { Expose, Type } from "class-transformer";
import { InquirySubmission } from "models";

export class conversionPointEvidences {
  @Expose({ name: "conversionEventId" })
  conversionEventId?: number;

  @Expose({ name: "conversionEventName" })
  conversionEventName: string;

  @Type(() => InquirySubmission)
  inquirySubmissions: InquirySubmission[];
}
