/**
 * URLが指定した正規表現とマッチするか判定する関数
 * @param str URLの文字列
 * @return 判定結果
 */
export const isURL = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
};
/**
 * URLを?の前後で分け,配列化する関数
 * @param str URLの文字列
 * @return 分割した配列
 */
export const disassemblyPath = (str: string) => {
  const urlList = str.split("?");
  return {
    pathname: urlList[0],
    search: urlList[1],
  };
};
