import React from "react";
import Styles from "stylesheets/shared/Loading.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import clsx from "clsx";

export const Loading: React.FC = () => (
  <div className={clsx(Styles.wrapper)}>
    <div className={Styles.container}>
      <h1 className={clsx(FontStyles.large, Styles.center)}>読み込み中...</h1>
      <div className={Styles.loader} />
    </div>
  </div>
);
