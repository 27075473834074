import React, { forwardRef, Ref } from "react";
import Slider, { Settings } from "react-slick";

interface Props {
  children: React.ReactNode;
  settings: Settings;
}

export const Carousel = forwardRef(({ children, settings }: Props, ref: Ref<Slider>) => (
  <Slider ref={ref} {...settings}>
    {children}
  </Slider>
));
