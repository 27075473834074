class DummyApi {
  async getPagenationToOffer<T>(offers: T[], offset: number): Promise<T[]> {
    return new Promise((resolve, reject) => {
      try {
        const res = offers?.filter((_, index) => offset < index + 1 && offset + 10 > index);
        resolve(res);
      } catch (err) {
        console.error("[DummyApi]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const dummyApi = new DummyApi();
