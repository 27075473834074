import { Expose, Type } from "class-transformer";
import { TimeSales } from "./index";
import { ConversionPoints } from "models";

export class DetailConversionPoints {
  // constructor() {
  //   this.timeSale.startAt = "";
  //   this.timeSale.endAt = "";
  // }

  name: string | null;

  point: number;

  basePoint: number;

  actualPoint: number;

  @Expose({ name: "timeSale" })
  @Type(() => TimeSales)
  timeSale?: TimeSales;

  @Type(() => ConversionPoints)
  conversionPoints?: ConversionPoints[];

  @Expose({ name: "isVideoConversionPoint" })
  isVideoConversionPoint: boolean;

  set primaryTimeSaleStartAt(date) {
    this.timeSale.startAt = date;
  }

  get primaryTimeSaleStartAt() {
    return this.timeSale?.startAt;
  }

  set primaryTimeSaleEndAt(date) {
    this.timeSale.endAt = date;
  }

  get primaryTimeSaleEndAt() {
    return this.timeSale?.endAt;
  }

  get primaryTimeSalePoint() {
    return this.timeSale?.point;
  }
}
