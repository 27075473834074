import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import defaultPath from "utiles/path";
import menuPath from "utiles/menuPath";
import { filterMenus } from "modules/filterMenus";
import NavigateNext from "@material-ui/icons/NavigateNext";
import stylesResponsive from "stylesheets/index/Menu.module.scss";
import stylesPortrait from "stylesheets/index/MenuPortrait.module.scss";
import freeIcon from "../../images/freecoinget_info.png";
import freeIconWeb from "../../images/freecoinget_info_web.png";
import freeIconLine from "../../images/tutorial_banner_line.png";
import freeIconPickup from "../../images/freecoinget_info_other.png";
import { makeStyles } from "@material-ui/styles";
import { MenuIcon } from "./MenuIcon";
import { Menus } from "models";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { offerType, offerwallType } from "types/offerwallType";
import clsx from "clsx";

interface Color {
  bgColor: string;
  txtColor: string;
}
interface Props {
  menus: Menus[];
  color: Color;
  isMenuOpen: boolean;
  offerwallType?: offerwallType;
  switchMenu?: () => void;
  tutorialHeaderImage?: string;
}

interface MenuLinkType {
  className?: string | undefined;
  menuType: number;
  displayMenu: Menus;
  txtColor: string;
  children: React.ReactNode;
  linkUrl: string;
}

export const Menu: React.FC<Props> = ({
  menus,
  color,
  isMenuOpen,
  offerwallType,
  switchMenu,
  tutorialHeaderImage,
}) => {
  const displayMenus: Menus[] = filterMenus(menus);
  const tripMileMenuHeight = (displayMenus.length + 1) * 48 + 20;
  const { location } = useSelector(selectRouter);
  const queries = new URLSearchParams(window.location.search);
  const suid = queries.get("suid") ?? "";
  const useStyles = makeStyles({
    iconStyle: {
      color: color.txtColor,
    },
  });
  const styles = useStyles();
  const setHeight = () => {
    document.documentElement.style.setProperty(
      "--torip-mile-menu-height",
      `${tripMileMenuHeight}px`
    );
  };

  const Styles = offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;

  const setLinkUrl = (linkUrl: string, suid: string) => {
    // 遷移先に{suid}の文字列があったらクエリパラメータからsuidを取得して置換する
    const replaceLinkUrl: string =
      linkUrl?.match("{suid}") && suid
        ? linkUrl?.replace("{suid}", encodeURIComponent(suid))
        : linkUrl?.replace("&suid={suid}", "");

    if (replaceLinkUrl?.match("/campaign")) {
      queries.delete("_owp");
      queries.delete("suid");
      return `${replaceLinkUrl}&${queries.toString()}`;
    }
    return replaceLinkUrl;
  };

  useEffect(() => {
    setHeight();
  }, []);

  const MenuLink: React.FC<MenuLinkType> = ({
    className,
    menuType,
    linkUrl,
    displayMenu,
    children,
    txtColor,
  }) => {
    const resultLinkUrl: string = useMemo(
      () => setLinkUrl(displayMenu.linkUrl, suid),
      [displayMenu, suid]
    );
    const queryString = new URLSearchParams(window.location.search);
    queryString.delete("position");

    // menuTypeが1のものは外部リンクになる react-routerの特性上Linkコンポーネントでは遷移できないのでaタグを使用
    return menuType !== 1 ? (
      <Link
        className={className}
        to={{
          pathname: menuPath[displayMenu.menuType],
          search: queryString.toString(),
          state: { display: displayMenu.title, previousPath: "", referrer: location },
        }}
        style={{ color: txtColor }}
      >
        <>{children}</>
      </Link>
    ) : linkUrl?.includes(`${defaultPath.INDEX}/free`) ? (
      <Link
        className={className}
        to={{
          pathname: `${defaultPath.INDEX}/free`,
          search: queryString.toString(),
          state: { display: displayMenu.title, previousPath: "", referrer: location },
        }}
        style={{ color: txtColor }}
      >
        <>{children}</>
      </Link>
    ) : (
      <a
        className={className}
        href={resultLinkUrl}
        target="_blank"
        rel="noreferrer"
        style={{ color: txtColor }}
      >
        {children}
      </a>
    );
  };
  // メニュー項目の配列から表示する要素を生成する
  const menuList = displayMenus.map((displayMenu: Menus, index: number) => (
    <li className={Styles.menuItem} key={index}>
      <MenuLink
        className={Styles.menuLink}
        menuType={displayMenu.menuType}
        linkUrl={displayMenu.linkUrl}
        displayMenu={displayMenu}
        txtColor={color.txtColor}
      >
        <p>{displayMenu.title}</p>
        <NavigateNext className={Styles.next} />
      </MenuLink>
    </li>
  ));

  // メニュー項目の配列から表示する要素を生成する
  const trimsMenuList = displayMenus.map((displayMenu: Menus, index: number) => (
    <li key={index} style={{ color: color.txtColor }}>
      <MenuLink
        className={clsx(Styles.menuLink, Styles.torimaMenuItem)}
        menuType={displayMenu.menuType}
        linkUrl={displayMenu.linkUrl}
        displayMenu={displayMenu}
        txtColor={color.txtColor}
      >
        <p>{displayMenu.title}</p>
      </MenuLink>
    </li>
  ));

  return (
    <>
      {isMenuOpen &&
        (offerwallType === offerType.MANGA || offerwallType === offerType.TRIP_MILE) && (
          <div className={Styles.overlay} onClick={switchMenu} />
        )}
      {isMenuOpen && (
        <>
          {offerwallType === offerType.TRIP_MILE ? (
            <div
              className={Styles.torimaContainer}
              style={{ backgroundColor: color.bgColor, height: `${tripMileMenuHeight}px` }}
            >
              <ul className={Styles.torimaMenuList} style={{ color: color.txtColor }}>
                <li>
                  <Link
                    className={clsx(Styles.menuLink, Styles.torimaMenuItem)}
                    to={{
                      pathname: `${defaultPath.INDEX}/tutorial`,
                      search: location.search,
                      state: { previousPath: "", referrer: location },
                    }}
                    style={{ color: color.txtColor }}
                  >
                    <p> 利用ガイド </p>
                  </Link>
                </li>
                {trimsMenuList}
              </ul>
            </div>
          ) : offerwallType !== offerType.MANGA ? (
            <div className={Styles.container} style={{ backgroundColor: color.bgColor }}>
              <Link
                to={{
                  pathname: `${defaultPath.INDEX}/tutorial`,
                  search: location.search,
                  state: { previousPath: "", referrer: location },
                }}
              >
                <img
                  className={Styles.image}
                  src={
                    offerwallType === offerType.LINE_MANGA
                      ? freeIconLine
                      : offerwallType === offerType.PICCOMA_WEB
                      ? freeIconWeb
                      : offerwallType === offerType.PICKUP
                      ? tutorialHeaderImage ?? freeIconPickup
                      : freeIcon
                  }
                />
              </Link>
              <ul className={Styles.list}>{menuList}</ul>
            </div>
          ) : (
            <div className={Styles.standerdContainer} style={{ backgroundColor: color.bgColor }}>
              <div className={Styles.header}>
                <MenuIcon className={styles.iconStyle} switchMenu={switchMenu} />
              </div>
              <div className={Styles.menuScroll}>
                <ul className={Styles.menuList}>
                  <Link
                    className={clsx(Styles.menuLink, Styles.menuItem)}
                    to={{
                      pathname: `${defaultPath.INDEX}/tutorial`,
                      search: location.search,
                      state: { previousPath: "", referrer: location },
                    }}
                    style={{ color: color.txtColor }}
                  >
                    <p> 利用ガイド </p>
                    <NavigateNext className={Styles.next} />
                  </Link>
                  {menuList}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
