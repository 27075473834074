import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  EnhancedStore,
  Middleware,
  AnyAction,
  Dispatch,
} from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter, RouterState } from "connected-react-router";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import modalState, { Modal } from "slices/modal";
import menuState, { Menu } from "slices/menu";
import popupState, { Popup } from "slices/popup";
import historyModules, { History as HistoryState } from "slices/history";
import OfferwallTopModules, { OfferwallTop } from "slices/offerwall";
import MediaModules, { Media } from "slices/media";
import { LocationState } from "types/locationType";
import DisplayIndexModules, { DisplayIndex } from "slices/displayIndex";
import ServiceTypeModules, { ServiceType } from "slices/serviceType";
import TutorialBannersModules, { TutorialBanners } from "slices/tutorialBanners";
import TutorialHeaderImageModules, { TutorialHeaderImage } from "slices/tutorialHeaderImage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["modal", "history"],
};

export interface RootState {
  modal: Modal;
  router: RouterState<LocationState>;
  history: HistoryState;
  offerwall: OfferwallTop;
  media: Media;
  menu: Menu;
  popup: Popup;
  displayIndex: DisplayIndex;
  serviceType: ServiceType;
  tutorialBanners: TutorialBanners;
  tutorialHeaderImage: TutorialHeaderImage;
}

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
const middlewares: Middleware<{}, any, Dispatch<AnyAction>>[] = getDefaultMiddleware({
  serializableCheck: false,
});

export const setupStore = (history: History<LocationState>): EnhancedStore<RootState> => {
  const store = configureStore({
    reducer: persistReducer(
      persistConfig,
      combineReducers({
        modal: modalState.reducer,
        menu: menuState.reducer,
        popup: popupState.reducer,
        history: historyModules.reducer,
        offerwall: OfferwallTopModules.reducer,
        media: MediaModules.reducer,
        displayIndex: DisplayIndexModules.reducer,
        serviceType: ServiceTypeModules.reducer,
        tutorialBanners: TutorialBannersModules.reducer,
        tutorialHeaderImage: TutorialHeaderImageModules.reducer,
        router: connectRouter(history),
      })
    ),
    middleware: middlewares,
  });

  return store;
};
