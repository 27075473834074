import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Media as MediaType, OldMedia } from "models";
import { mediaApi } from "apis/media";
import { fetchAsyncGetTop, Offers } from "./offerwall";

export interface MediaApi extends Omit<MediaType | OldMedia, ""> {
  error: boolean;
  isLoad: boolean;
}

export type Media = Partial<MediaApi>;

export const MediaState: Media = {
  error: false,
  isLoad: false,
};

export const fetchAsyncGetMedia = createAsyncThunk(
  "offerwall/media",
  // クエリパラメータは変わるのでgetMediaの引数と同じ型に
  async (query: URLSearchParams) => {
    const params = new URLSearchParams(location.search);
    const _owp = params.get("_owp");
    const data = await mediaApi.getMedia(query, _owp);
    return data;
  }
);

const MediaModules = createSlice({
  name: "MediaModules",
  initialState: MediaState,
  reducers: {
    setMedia: (state, action: PayloadAction<Media>) => ({
      ...action.payload,
      ...state,
    }),
    setError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      error: action.payload,
    }),
  },
  extraReducers: (builder) => {
    /** ******************************************************
     *     Top取得時
     * ******************************************************* */
    builder.addCase(fetchAsyncGetTop.pending, (state) => ({
      // 非同期処理中
      ...state,
      isLoad: false,
    }));
    builder.addCase(fetchAsyncGetTop.fulfilled, (state, action: PayloadAction<Offers>) => ({
      // 非同期処理成功時
      ...state,
      isLoad: true,
      error: false,
      ...(action.payload.media as Media),
    }));
    builder.addCase(fetchAsyncGetTop.rejected, (state) => ({
      // 非同期処理失敗時
      ...state,
      isLoad: true,
      error: true,
    }));
    /** ******************************************************
     *     media
     * ******************************************************* */
    builder.addCase(fetchAsyncGetMedia.pending, (state) => ({
      // 非同期処理中
      ...state,
      isLoad: false,
      error: false,
    }));
    builder.addCase(fetchAsyncGetMedia.fulfilled, (state, action: PayloadAction<Media>) => ({
      // 非同期処理成功時
      ...state,
      ...(action.payload as Media),
      isLoad: true,
      error: false,
    }));
    builder.addCase(fetchAsyncGetMedia.rejected, (state) => ({
      // 非同期処理失敗時
      ...state,
      isLoad: true,
      error: true,
    }));
  },
});
export default MediaModules;
