import React from "react";
import { Link } from "react-router-dom";
import Styles from "stylesheets/index/HorizontalOffersList.module.scss";
import { NextIcon } from "components/shared/NextIcon";
import { LocationState } from "history";

interface To {
  pathname: string;
  search: string;
  state: LocationState;
}

export interface TertiaryProps {
  type: "challenge" | "easy" | "many";
  children: React.ReactNode;
  title: string;
  to: To;
  style?: React.CSSProperties;
  isMore?: boolean;
}

export const HorizontalBox = ({ title, children, to, style, isMore }: TertiaryProps) => (
  <>
    <div className={Styles.wrapper}>
      <div className={Styles.description}>
        <div className={Styles.titleWrapper}>
          <span className={Styles.title} style={style} data-testid="horizontalTitle">
            {title}
          </span>
        </div>
        {isMore && (
          <Link data-testid="showAll" className={Styles.showAll} to={to}>
            <div className={Styles["showMore-link-wrapper"]}>
              <span>もっと見る</span>
              <NextIcon className={Styles.iconStyle} />
            </div>
          </Link>
        )}
      </div>
      <div className={Styles.lists} data-testid="hrOfferList">
        {children}
      </div>
    </div>
  </>
);
