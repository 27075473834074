import React from "react";
import { OfferIcon } from "components/shared/OfferIcon";
import Styles from "stylesheets/index/OthersSurveyListItem.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { OthersSurveyPointResult } from "components/index/OthersSurveyPointResult";
import clsx from "clsx";
import forwardMark from "images/forward_button.png";

interface Color {
  appAreaTxtColor: string;
  appAreaBgColor: string;
  cvConditionColor: string;
}

interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

interface Props {
  displayName: string;
  cvCondition: string;
  color: Color;
  basePoint: number;
  actualPoint: number;
  basePointMin: number;
  actualPointMin: number;
  pointIconUrl: string;
  pointUnit: string;
  superSale: SuperSale;
  situation: string;
  researchId?: string;
  iconUrl: string;
  company: string;
  isPickUp: boolean;
}

export const OthersSurveyListItem = React.memo(
  ({
    displayName,
    cvCondition,
    color,
    basePoint,
    actualPoint,
    basePointMin,
    actualPointMin,
    pointIconUrl,
    pointUnit,
    superSale,
    situation,
    researchId,
    iconUrl,
    company,
    isPickUp,
  }: Props) => (
    <div className={Styles.wrapper} style={{ backgroundColor: color.appAreaBgColor }}>
      <div className={Styles.offer}>
        <OfferIcon src={iconUrl} />
        <div className={Styles.offer_content}>
          <div className={Styles.offer_content_upper}>
            <div className={Styles.info}>
              <h3 className={Styles.info_name} style={{ color: color.appAreaTxtColor }}>
                {displayName}
                <img src={forwardMark} className={Styles.info_image} />
              </h3>
              <div
                className={clsx(Styles.info_cv, FontStyles.tiny)}
                style={{ color: color.cvConditionColor, marginBottom: "7px" }}
              >
                No.{researchId}
              </div>
              <div
                className={clsx(Styles.info_cv, FontStyles.tiny)}
                style={{ color: color.cvConditionColor }}
              >
                {cvCondition}
              </div>
              {situation === "未回答" && (
                <div className={Styles.pointResult}>
                  <OthersSurveyPointResult
                    color={{
                      appAreaTxtColor: color.appAreaTxtColor,
                    }}
                    coinStyle={Styles.coin}
                    isSur={basePoint > basePointMin}
                    superSale={superSale}
                    pointIconUrl={pointIconUrl}
                    basePoint={basePoint}
                    actualPoint={actualPoint}
                    basePointMin={basePointMin}
                    actualPointMin={actualPointMin}
                  />
                  {isPickUp && <span className={Styles.pointUnit}>{pointUnit}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          Styles.info_cv,
          FontStyles.tiny,
          Styles["offer_content_transition-destination-text"]
        )}
        style={{ color: color.cvConditionColor }}
      >
        {situation === "未回答"
          ? `※${company ?? ""}が管理するアンケート回答画面に遷移します`
          : situation === "回答済"
          ? "回答済み"
          : "期限切れ"}
      </div>
    </div>
  )
);
