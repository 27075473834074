import { Expose } from "class-transformer";

export class ChallengeConversionPoints {
  name: string | null;

  point: number;

  actualPoint: number;

  basePoint: number;

  @Expose({ name: "timeSale" })
  timeSale?: {
    point: number;
    startAt: Date;
    endAt: Date;
  };

  @Expose()
  get primaryTimeSalePoint() {
    return this.timeSale?.point ?? 0;
  }

  @Expose()
  get primaryTimeSaleStartAt() {
    return this.timeSale?.startAt ?? null;
  }

  @Expose()
  get primaryTimeSaleEndAt() {
    return this.timeSale?.endAt ?? null;
  }
}
