interface FilterObject {
  search: { [key: string]: string };
  removeObj?: { [key: string]: string[] };
}

interface RemoveObject {
  search: { [key: string]: string };
  removeList?: string[];
}

interface Conversion {
  search: { [key: string]: string };
  obj?: { [key: string]: string };
}

interface IsQuery {
  search: { [key: string]: string };
  listInObj?: { [key: string]: string[] };
  list?: string[];
  obj?: { [key: string]: string };
}

const findObj = (key: string, value: string, removeObj: { [key: string]: string[] }) =>
  Object.entries(removeObj).find(
    ([innerKey, innerValue]) => innerKey === key && innerValue.includes(value)
  );

export default {
  /**
   * クエリ文字列をオブジェクトに変換する関数
   * @param str クエリ文字列
   * @return オブジェクトに変更されたクエリ文字列
   *  */
  object: (str: string): { [key: string]: string } =>
    str
      ?.split("&")
      ?.reduce(
        (obj, data) => ({ ...obj, [data.replace("?", "").split("=")[0]]: data.split("=")[1] }),
        {}
      ),
  /**
   * オブジェクトをクエリ文字列に変換する関数
   * @param obj オブジェクト
   * @return クエリ文字列に変更されたオブジェクト
   *  */
  str: (obj: { [key: string]: string }): string => new URLSearchParams({ ...obj }).toString(),

  /** *****************************************************************
   * removeObjは想定されていないクエリを削除
   * { offer: ["0"] }
   *********************************************************************** */
  /**
   * オブジェクトから想定されていないクエリを削除する関数
   * @param search オブジェクト
   * @param removeObj オブジェクト
   * @return 想定されていないクエリを削除されたオブジェクト
   *  */
  filterFromObject: ({ search, removeObj }: FilterObject): { [key: string]: string } =>
    Object.entries(search)
      .map(
        ([key, value]) =>
          !findObj(key, value, removeObj) && {
            [key]: value,
          }
      )
      .filter((data) => data)
      .reduce((obj, data) => ({ ...obj, ...data }), {}),

  /** *****************************************************************
   * removeListは想定されていないクエリを削除
   * 例）["offer"]
   *********************************************************************** */
  /**
   * オブジェクトから想定されていないクエリを削除する関数
   * @param search オブジェクト
   * @param removeList オブジェクト
   * @return 想定されていないクエリを削除されたオブジェクト
   *  */
  removeFromList: ({ search, removeList }: RemoveObject) =>
    Object.entries(search)
      .map(
        ([key, value]) =>
          !removeList.includes(key) && {
            [key]: value,
          }
      )
      .filter((data) => data)
      .reduce((obj, data) => ({ ...obj, ...data }), {}),

  /** *****************************************************************
   * removeObjは想定されていないクエリを削除
   * listInObj: { offer: ["0"] }
   * list: ["offer"]
   * obj: { key: value }
   *********************************************************************** */
  /**
   * オブジェクトから想定されていないクエリを削除する関数
   * @param search オブジェクト
   * @param listInObj オブジェクト
   * @return 想定されていないクエリを削除されたオブジェクト
   *  */
  isQuery: ({ search, listInObj, list, obj }: IsQuery): boolean => {
    const isList = !!list;
    const isObj = !!obj;

    return isObj
      ? Object.entries(search).some(([key]) => Object.keys(obj).includes(key))
      : isList
      ? Object.entries(search).some(([key]) => list.includes(key))
      : Object.entries(search).some(([key, value]) => findObj(key, String(value), listInObj));
  },
  /** *****************************************************************
   * 特定パラメーターの変換
   * 例）obj: { _article: "offer" }
   *********************************************************************** */
  /**
   * オブジェクトの特定パラメーターの変換する関数
   * @param search オブジェクト
   * @param obj オブジェクト
   * @return 得定パラメーターの変換されたオブジェクト
   *  */
  conversionObj: ({ search, obj }: Conversion) =>
    Object.entries(search)
      .map((data) => {
        const [key, value] = data;
        const conversion = Object.entries(obj)
          .map(([k, v]) => k === key && { 0: v, 1: value })
          .reduce((o, d) => ({ ...o, ...d }), {});
        const list = Object.values({ ...data, ...conversion }) as string[];

        return { [list[0]]: list[1] };
      })
      .filter((data) => data)
      .reduce((obj, data) => ({ ...obj, ...data }), {}),
};
