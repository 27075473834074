import "intersection-observer";
import React, { useEffect } from "react";
import "reflect-metadata";

import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { setupStore } from "store";
import { createBrowserHistory, History } from "history";
import { ConnectedRouter } from "connected-react-router/immutable";
import { Routing } from "./routes";
import { HelmetProvider } from "react-helmet-async";
import ScrollTop from "components/common/ScrollTop";
import { LocationState } from "types/locationType";

// store設定
const history = createBrowserHistory() as History<LocationState>;
const store = setupStore(history);
const pstore = persistStore(store);

export const App: React.FC = () => {
  const setHeight = () => {
    const vh = window.screen.height * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const topurlVh = window.innerHeight * 0.01;
    const urlVh = window.outerHeight - window.innerHeight;
    document.documentElement.style.setProperty("---offer-top-url-var", `${topurlVh}px`);
    document.documentElement.style.setProperty("--url-var", `${urlVh}px`);
  };

  useEffect(() => {
    window.addEventListener("load", setHeight);
    window.addEventListener("resize", setHeight);
    window.addEventListener("orientationchange", setHeight);
    return () => {
      window.removeEventListener("load", setHeight);
      window.removeEventListener("resize", setHeight);
      window.addEventListener("orientationchange", setHeight);
    };
  }, []);

  const props = {
    history,
  };

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<></>} persistor={pstore}>
          <ScrollTop history={history}>
            <ConnectedRouter {...props}>
              <Routing />
            </ConnectedRouter>
          </ScrollTop>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
};
